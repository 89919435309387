import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProjectState {
  websiteIds: number[];
  encryptedWebsiteIds: string[];
  isBulkSelecting: boolean;
  nameEditId: number | string | null;
  nameEditValue: string | null;
  projectOffset: number;
}

const initialState: ProjectState = {
  websiteIds: [],
  encryptedWebsiteIds: [],
  isBulkSelecting: false,
  nameEditId: null,
  nameEditValue: null,
  projectOffset: 0,
};

const updateBulkSelecting = (state: ProjectState) => {
  state.isBulkSelecting =
    state.websiteIds.length > 0 || state.encryptedWebsiteIds.length > 0;
};

const projectSlice = createSlice({
  name: 'project_new',
  initialState,
  reducers: {
    setWebsiteIds: (state, action: PayloadAction<number[]>) => {
      state.websiteIds = action.payload;
      updateBulkSelecting(state);
    },
    setEncryptedWebsiteIds: (state, action: PayloadAction<string[]>) => {
      state.encryptedWebsiteIds = action.payload;
      updateBulkSelecting(state);
    },
    clearWebsiteIds: (state) => {
      state.websiteIds = [];
      state.encryptedWebsiteIds = [];
      state.isBulkSelecting = false;
    },
    addWebsiteId: (state, action: PayloadAction<number>) => {
      state.websiteIds.push(action.payload);
      state.isBulkSelecting = true;
    },
    removeWebsiteId: (state, action: PayloadAction<number>) => {
      state.websiteIds = state.websiteIds.filter((id) => id !== action.payload);
      updateBulkSelecting(state);
    },
    addEncryptedWebsiteId: (state, action: PayloadAction<string>) => {
      state.encryptedWebsiteIds.push(action.payload);
      state.isBulkSelecting = true;
    },
    removeEncryptedWebsiteId: (state, action: PayloadAction<string>) => {
      state.encryptedWebsiteIds = state.encryptedWebsiteIds.filter(
        (id) => id !== action.payload
      );
      updateBulkSelecting(state);
    },
    setNameEditId: (state, action: PayloadAction<number | string | null>) => {
      state.nameEditId = action.payload;
    },
    setNameEditValue: (state, action: PayloadAction<string | null>) => {
      state.nameEditValue = action.payload;
    },
    clearNameEditId: (state) => {
      state.nameEditId = null;
      state.nameEditValue = null;
    },
    setProjectOffset: (state, action: PayloadAction<number>) => {
      state.projectOffset = action.payload;
    },
    resetProjectOffset: (state) => {
      state.projectOffset = 0;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setWebsiteIds,
  setEncryptedWebsiteIds,
  clearWebsiteIds,
  addWebsiteId,
  removeWebsiteId,
  addEncryptedWebsiteId,
  removeEncryptedWebsiteId,
  setNameEditId,
  setNameEditValue,
  clearNameEditId,
  setProjectOffset,
  resetProjectOffset,
} = projectSlice.actions;

export default projectSlice.reducer;
