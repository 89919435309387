import {
  ChartLine,
  CircleHelp,
  ClipboardPen,
  Inbox,
  LayoutGrid,
  MessageSquarePlus,
  Settings,
  SquareKanban,
  UserRound,
  WorkflowIcon,
} from 'lucide-react';

export function getMenuList() {
  const application = [
    {
      title: 'Projects',
      url: '/',
      icon: LayoutGrid,
    },
    {
      title: 'Inbox',
      url: '/tasks',
      icon: Inbox,
    },
    {
      title: 'Boards',
      url: '/board',
      icon: SquareKanban,
    },
    {
      title: 'Forms',
      url: '/forms',
      icon: ClipboardPen,
    },
  ];
  const workflow = [
    {
      title: 'Workflows',
      url: '/workflows',
      icon: WorkflowIcon,
    },
    {
      title: 'People',
      url: '/users',
      icon: UserRound,
    },
    {
      title: 'Analytics',
      url: '/dashboard',
      icon: ChartLine,
    },
    {
      title: 'Settings',
      url: '/settings',
      icon: Settings,
    },
  ];

  const help = [
    {
      title: 'Help Center',
      url: 'https://atarim.io/help/',
      icon: CircleHelp,
      target: '_blank',
    },
    {
      title: 'Start a Live Chat',
      url: '#',
      icon: MessageSquarePlus,
      target: '',
    },
  ];

  return {
    application,
    workflow,
    help,
  };
}
