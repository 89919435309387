import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import {
  ERROR_USER_AUTHENTICATE,
  LOGOUT,
  SET_TOKEN_IN_REDUX,
  SUCCESS_USER_AUTHENTICATE,
} from 'actions/user';
import { SET_SELECTED_WORKSPACE } from 'actions/workspace';
import { usePostHog } from 'posthog-js/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { sendMessageToExtension } from 'utils/chromeExtension';
import { clearLocalStorage, setUserLoggedIn } from '../api';
import { acceptInvitation } from '../api/collaborate';
import { authenticate, fetchProfile, logout } from '../api/user';
import { getWorkspaces } from '../api/workspace';

export const useAuth = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);
  const [token, setToken] = useState(
    queryClient.getQueryData(['authenticate'])
  );
  const isFetchingAuthenticate = useIsFetching({ queryKey: ['authenticate'] });
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const storedWorkspace = JSON.parse(localStorage.getItem('selectedWorkspace'));

  const authenticateUser = async () => {
    let tokenResponse;
    if (searchParams.has('token')) {
      const token = searchParams.get('token');
      tokenResponse = await authWithInvitation(token);
    }
    const redirectPath = location.state?.from || '/';
    try {
      if (!tokenResponse) {
        if (isFetchingAuthenticate) {
          await queryClient.ensureQueryData({
            queryKey: ['authenticate'],
            queryFn: () => authenticate(),
          });
          tokenResponse = queryClient.getQueryData(['authenticate']);
        } else if (queryClient.getQueryData(['authenticate'])) {
          tokenResponse = queryClient.getQueryData(['authenticate']);
        } else {
          tokenResponse = await authenticate();
        }
      }

      if (!tokenResponse) {
        setIsLoadingAuth(false);
        throw new Error('Authentication failed');
      }

      setToken(tokenResponse);
      queryClient.setQueryData(['authenticate'], tokenResponse);
      dispatch({ type: SET_TOKEN_IN_REDUX, token: tokenResponse });
      setUserLoggedIn();
      sendMessageToExtension({
        apiToken: tokenResponse.access_token,
      });

      const { data: workspaces } = await getWorkspaces();
      if (!workspaces || workspaces.length === 0) {
        console.warn(
          'No workspace found for this user, please contact support!'
        );
        setIsLoadingAuth(false);
        logoutUser();
        return;
      }

      queryClient.setQueryData(['workspaces'], workspaces);
      dispatch({ type: 'SUCCESS_FETCH_WORKSPACES', data: workspaces });

      if (
        workspaces.length > 1 &&
        !storedWorkspace &&
        location.pathname !== '/collaborate' &&
        !redirectPath.includes('activation_callback')
      ) {
        setIsLoadingAuth(false);
        navigate('/workspaces-login' + location.state?.search, {
          replace: true,
        });
        return;
      }

      let selectedWorkspace;
      if (storedWorkspace) {
        selectedWorkspace = workspaces.find(
          (workspace) => workspace.id === storedWorkspace.id
        );
        if (!selectedWorkspace) {
          selectedWorkspace = workspaces[0];
          localStorage.setItem(
            'selectedWorkspace',
            JSON.stringify(selectedWorkspace)
          );
        }
      } else {
        selectedWorkspace = workspaces[0];
        localStorage.setItem(
          'selectedWorkspace',
          JSON.stringify(selectedWorkspace)
        );
      }

      dispatch({
        type: SET_SELECTED_WORKSPACE,
        workspace: selectedWorkspace,
      });

      const { data: profile } = await fetchProfile(selectedWorkspace.id);
      posthog?.identify(profile.id + '_' + profile.email, {
        email: profile.email,
        plan: profile?.plan?.title,
        username: profile.username,
        environment: import.meta.env.VITE_APP_BASE_URL,
      });
      if (window.gist) {
        window.gist.identify(profile.id, { email: profile.email });
      }
      if (!profile) {
        setIsLoadingAuth(false);
        await logout();
        return;
      }

      queryClient.setQueryData(['profile', selectedWorkspace.id], profile);
      dispatch({ type: 'SUCCESS_PROFILE_FETCH', userData: profile });
      dispatch({ type: 'IS_NEW_USER', isNewUser: false });

      setIsLoadingAuth(false);
      dispatch({
        type: SUCCESS_USER_AUTHENTICATE,
        token: tokenResponse.access_token,
      });

      if (location.pathname !== '/collaborate') {
        navigate(redirectPath + (location.state?.search || location.search), {
          replace: true,
        });
      }
    } catch (error) {
      console.error('Authentication error:', error);
      setIsLoadingAuth(false);
      clearLocalStorage();
      if (window.gist) {
        window.gist.chat('shutdown');
      }
      dispatch({ type: ERROR_USER_AUTHENTICATE });
    }
  };

  const authWithInvitation = async (token) => {
    try {
      const response = await acceptInvitation(token);
      return response.result;
    } catch (error) {
      console.error('Authentication error:', error);
      return null;
    }
  };

  const logoutUser = async () => {
    await logout();
    sendMessageToExtension({
      type: 'logout',
    });
    clearLocalStorage();
    // Reset posthog user
    posthog?.reset();
    // Reset Gist user
    if (window.gist) {
      window.gist.chat('shutdown');
    }
    // Reset productFruits user
    if (window.productFruits) {
      window.productFruits.services.destroy();
    }
    dispatch({ type: LOGOUT });
    queryClient.clear();
    navigate('/login');
  };

  return { isLoadingAuth, authenticateUser, logoutUser, token };
};
