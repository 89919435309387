import { call, select } from 'redux-saga/effects';
import { enterpriseClients } from './constants';

export function secToTime(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;
  return [h, m, s];
}

export function timeToSec(hours, minutes, seconds) {
  const h = Number(hours);
  const m = Number(minutes);
  const s = Number(seconds);

  return h * 3600 + m * 60 + s;
}

export function formatTime(hours, minutes, seconds) {
  return (
    (hours > 9 ? hours : '0' + hours) +
    ':' +
    (minutes > 9 ? minutes : '0' + minutes) +
    ':' +
    (seconds > 9 ? seconds : '0' + seconds)
  );
}

export function avatarInitials(name) {
  return name.substring(0, 2).toUpperCase();
}

export function reactAvatarInitials(name) {
  let name1 = name.substring(0, 1).toUpperCase();
  let name2 = name.substring(1, 2).toUpperCase();
  return name1 + ' ' + name2;
}

// taken from https://www.w3resource.com/javascript/form/email-validation.php [Modified]
export function validateEmail(mail) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);
}

export function toCapitalize(string) {
  return string
    .split()
    .map((k, i) => (i === 1 ? k.toUpperCase() : k.toLowerCase()))
    .join();
}

export function convertKeyToLabel(string) {
  return string
    .split('_')
    .map((k) =>
      k
        .split('')
        .map((l, i) => {
          return i === 0 ? l.toUpperCase() : l.toLowerCase();
        })
        .join('')
    )
    .join(' ');
}

export function videoIdGenerator(text) {
  // to extract the youtube link from any text
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g;
  const matches = text.match(regex);

  if (matches) {
    // Return the first match (youtube video url)
    const url = matches[0];
    // now we have to extract the id from the youtube video link
    let indexOfId = url.replace(/\s/g, '').search('v=');
    if (indexOfId > 0) {
      let id = url.slice(indexOfId + 2).split('&')[0];
      return id;
    } else if (url.search('.be/') > 0) {
      let indexIdOfShortVideo = url.search('.be/');
      let id = url.slice(indexIdOfShortVideo + 4).split('&')[0];
      return id;
    } else {
      return '';
    }
  }

  // If no match found, return null or handle it as needed
  return null;
}

export const stringRepeat = (str, n) => {
  let rStr = str;
  for (let i = 1; i < n; i++) {
    rStr += str;
  }
  return rStr;
};

export const removeElementFromArray = (array, element) => {
  return array.filter((value) => value !== element);
};

export const addQueryString = (url, queryString) => {
  const regex = /^.*\?.*$/;
  if (regex.test(url)) {
    // query string exists
    return `${url}&${queryString}`;
  } else {
    return `${url}?${queryString}`;
  }
};

export function* getEntriesFilterObject() {
  const getFilters = (state) => state.time_entries.filters;
  const filters = yield select(getFilters);

  const date = filters.date
    .filter((date) => date.checked)
    .map((date) => date.value);
  const users = filters.users
    .filter((user) => user.checked)
    .map((user) => user.value);
  const billable = filters.billable
    .filter((bill) => bill.checked)
    .map((bill) => bill.value);
  const sites = filters.sites
    .filter((site) => site.checked)
    .map((site) => site.value);
  const projects = filters.projects
    .filter((project) => project.checked)
    .map((project) => project.value);

  return {
    date: date,
    users: users,
    billable: billable,
    sites: sites,
    projects: projects,
  };
}

export function* getEntriesCustomFilterObject() {
  const getFiltersCustomObj = (state) => state.time_entries.customFilterData;
  const filters = yield select(getFiltersCustomObj);
  const getFilters = (state) => state.time_entries.filters;
  const filtersSite = yield select(getFilters);
  const sites = filtersSite.sites
    .filter((site) => site.checked)
    .map((site) => site.value);
  return {
    start_date: filters.start_date,
    end_date: filters.end_date,
    sites: sites,
  };
}

export function* getFilterObject() {
  const getFilters = (state) => state.tasks.filters;
  const getSearchText = (state) => state.tasks.searchText;
  const filters = yield select(getFilters);
  const searchText = yield select(getSearchText);
  const isAllTasks = yield call(getIsAllTasks);
  const task_notify_users = isAllTasks
    ? []
    : filters.task_notify_users
      ? filters.task_notify_users.filter((n) => n.checked).map((n) => n.value)
      : null;
  const task_urgency = filters.task_urgency
    ? filters.task_urgency.filter((n) => n.checked).map((n) => n.value)
    : null;
  const task_status = filters.task_status
    ? filters.task_status.filter((n) => n.checked).map((n) => n.value)
    : null;
  const task_types = filters.task_types
    ? filters.task_types.filter((n) => n.checked).map((n) => n.value)
    : null;
  const task_tags = isAllTasks
    ? []
    : filters.task_tags
      ? filters.task_tags.filter((n) => n.checked).map((n) => n.value)
      : null;
  const notify_wp_feedback_users = filters?.notify_wp_feedback_users
    ? filters.notify_wp_feedback_users
        .filter((n) => n.checked)
        .map((n) => n.value)
    : [];
  // Commented line is ignoring nofify user filter when all project filter is active
  // const notify_wp_feedback_users = isAllTasks ? [] : filters.notify_wp_feedback_users ? filters.notify_wp_feedback_users.filter(n => n.checked).map(n => n.value) : null;

  if (task_notify_users === null) {
    return {
      apply_filter: 0,
      task_notify_users: task_notify_users,
      task_urgency: task_urgency,
      task_status: task_status,
      task_types: task_types,
      task_title_keyword: searchText,
      task_tags: task_tags,
      notify_wp_feedback_users: notify_wp_feedback_users,
    };
  }

  return {
    apply_filter:
      task_notify_users.length > 0 ||
      task_status.length > 0 ||
      task_urgency.length > 0 ||
      task_types.length > 0 ||
      task_tags.length > 0 ||
      notify_wp_feedback_users.length > 0 ||
      Boolean(searchText),
    task_notify_users: task_notify_users,
    task_urgency: task_urgency,
    task_status: task_status,
    task_types: task_types,
    task_title_keyword: searchText,
    task_tags: task_tags,
    notify_wp_feedback_users: notify_wp_feedback_users,
  };
}

export function fetchInitFilterObject() {
  return {
    apply_filter: true,
    task_notify_users: [],
    task_urgency: [],
    task_status: [],
    task_types: [],
    task_title_keyword: '',
  };
}

export function* getIsAllTasks() {
  const selectIsAllTasks = (state) => state.tasks.isAllTasks;
  const isAllTasks = yield select(selectIsAllTasks);
  return isAllTasks;
}

export function* getSelectedWebsiteId() {
  const getWebsiteId = (state) => state.dashboard.selectedWebsite; // no other way?
  const websiteId = yield select(getWebsiteId);
  return websiteId;
}

export function updateActiveRouteWithParam(
  selectedWebsite,
  selectedTask,
  emailSelected
) {
  localStorage.removeItem('selectedWebsite');
  localStorage.removeItem('selectedTask');

  const queryParams = localStorage.getItem('performSearchText');
  let newQuery = '';
  var newurl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname;
  if (
    selectedWebsite !== '' &&
    selectedWebsite !== null &&
    selectedTask !== '' &&
    selectedTask !== null
  ) {
    localStorage.setItem('selectedWebsite', selectedWebsite);
    localStorage.setItem('selectedTask', selectedTask);
    newQuery = `?website=${selectedWebsite}&taskid=${selectedTask}`;
  } else {
    if (selectedWebsite === '') {
      newQuery = '';
    } else {
      if (selectedWebsite !== '' && selectedWebsite !== null) {
        localStorage.setItem('selectedWebsite', selectedWebsite);
        newQuery = `?website=${selectedWebsite}`;
      } else {
        newQuery = '';
      }
    }
  }
  if (newQuery === '' && queryParams !== '' && queryParams !== null) {
    newQuery = `?${queryParams}`;
  } else {
    if (queryParams !== '' && newQuery !== '' && queryParams !== null) {
      newQuery = newQuery + `&${queryParams}`;
    }
  }
  newurl = newurl + newQuery;
  if (
    window.location.pathname.indexOf('/tasks') > -1 ||
    window.location.pathname.indexOf('/board') > -1
  ) {
    window.history.pushState({ path: newurl }, '', newurl);
  }

  if (
    emailSelected !== null &&
    emailSelected !== '' &&
    emailSelected !== undefined
  ) {
    localStorage.setItem('emailSelected', emailSelected);
  }
}

export function* getTaskUpdateMsg() {
  const getUpdateText = (state) => state.tasks.taskUpdateMessage;
  const updateText = yield select(getUpdateText);
  return updateText;
}

// Get device name
export function getDeviceName() {
  let device = 'Generic';
  const patterns = {
    Linux: /Linux/i,
    Android: /Android/i,
    'Chrome OS': /CrOS/i,
    iPad: /iPad/i,
    iPhone: /iPhone/i,
    iPod: /iPod/i,
    macOS: /Macintosh/i,
    Windows: /IEMobile|Windows/i,
  };
  Object.keys(patterns).map(
    (deviceName) =>
      navigator.userAgent.match(patterns[deviceName]) && (device = deviceName)
  );
  return device;
}

export function getBrowserName() {
  let currentBrowser = 'Not known';
  let version = '';
  if (navigator.userAgent.search('MSIE') >= 0) {
    currentBrowser = 'MS Internet Explorer';
    let position = navigator.userAgent.search('MSIE') + 5;
    let end = navigator.userAgent.search('; Windows');
    version = navigator.userAgent.substring(position, end);
  } else if (navigator.userAgent.search('Chrome') >= 0) {
    currentBrowser = 'Google Chrome';
    let position = navigator.userAgent.search('Chrome') + 7;
    let end = navigator.userAgent.search(' Safari');
    version = navigator.userAgent.substring(position, end);
  } else if (navigator.userAgent.search('Firefox') >= 0) {
    currentBrowser = 'Mozilla Firefox';
    let position = navigator.userAgent.search('Firefox') + 8;
    version = navigator.userAgent.substring(position);
  } else if (
    navigator.userAgent.search('Safari') >= 0 &&
    navigator.userAgent.search('Chrome') < 0
  ) {
    //<< Here
    currentBrowser = 'Apple Safari';
    let position = navigator.userAgent.search('Version') + 8;
    let end = navigator.userAgent.search(' Safari');
    version = navigator.userAgent.substring(position, end);
  } else if (navigator.userAgent.search('Opera') >= 0) {
    currentBrowser = 'Opera';
    let position = navigator.userAgent.search('Version') + 8;
    version = navigator.userAgent.substring(position);
  } else {
    currentBrowser = 'Other';
  }
  return {
    name: currentBrowser,
    version,
  };
}

export function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

export const beautifySeconds = (seconds, short = false) => {
  var days = Math.floor(seconds / (24 * 60 * 60));
  seconds -= days * (24 * 60 * 60);
  var hours = Math.floor(seconds / (60 * 60));
  seconds -= hours * (60 * 60);
  var minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  if (short) {
    return (
      (0 < days ? days + (days > 1 ? ' D ' : ' D ') : '') +
      (0 < hours ? hours + (hours > 1 ? ' H ' : ' H ') : '') +
      (0 < minutes ? minutes + (minutes > 1 ? ' M ' : ' M ') : '')
    );
  } else {
    return (
      (0 < days ? days + (days > 1 ? ' days ' : ' day ') : '') +
      (0 < hours ? hours + (hours > 1 ? ' hours ' : ' hour ') : '') +
      (0 < minutes ? minutes + (minutes > 1 ? ' minutes ' : ' minute ') : '')
    );
  }
};

export const toHMS = (totalSeconds) => {
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = Math.floor(totalSeconds % 60);

  hours = String(hours).padStart(2, '0');
  minutes = String(minutes).padStart(2, '0');
  seconds = String(seconds).padStart(2, '0');

  return hours + ':' + minutes + ':' + seconds;
};

export const get_url_extension = (url) => {
  return url.split(/[#?]/)[0].split('.').pop().trim();
};

export const domSanitize = (html) => {
  var doc = document.createElement('div');
  doc.innerHTML = html;
  return doc.innerHTML;
};

export const beautifySecondsCollaborate = (seconds, short = false) => {
  var days = Math.floor(seconds / (24 * 60 * 60));
  seconds -= days * (24 * 60 * 60);
  var hours = Math.floor(seconds / (60 * 60));
  seconds -= hours * (60 * 60);
  var minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  if (short) {
    return (
      (0 < days ? days + (days > 1 ? ' D ' : ' D ') : '') +
      (0 < hours ? hours + (hours > 1 ? ' H ' : ' H ') : '') +
      (0 < minutes ? minutes + (minutes > 1 ? ' M ' : ' M ') : '')
    );
  } else {
    return (
      (0 < days ? days + (days > 1 ? 'd ' : 'd ') : '') +
      (0 < hours ? hours + (hours > 1 ? 'h ' : 'h ') : '') +
      (0 < minutes ? minutes + (minutes > 1 ? 'm ' : 'm ') : '')
    );
  }
};

export const validURL = (str) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator

  return !!pattern.test(str);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // Check if the size exceeds 20 MB
  const fileSizeInMB = bytes / Math.pow(k, 2);
  if (fileSizeInMB > 20) {
    // You can also throw an Error instead of returning a message if that's more appropriate for your use case
    return 'File size exceeds 20 MB';
  }

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
  const ia = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

// https://stackoverflow.com/a/45889138/12392460
export const createCookie = (name, value, minutes) => {
  if (minutes) {
    var date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    var expires = '; expires=' + date.toGMTString();
  } else {
    var expires = '';
  }
  document.cookie = name + '=' + value + expires + '; path=/';
};

// https://stackoverflow.com/a/5968306/12392460
export const getCookie = (name) => {
  var dc = document.cookie;
  var prefix = name + '=';
  var begin = dc.indexOf('; ' + prefix);
  if (begin === -1) {
    begin = dc.indexOf(prefix);
    if (begin !== 0) return null;
  } else {
    begin += 2;
    var end = document.cookie.indexOf(';', begin);
    if (end === -1) {
      end = dc.length;
    }
  }

  return decodeURI(dc.substring(begin + prefix.length, end));
};

//function for sorting the notifications according to date
export const sortingNotifications = (a, b) => {
  const [aValue, aUnit] = a.created_at.split(/(\d+)/).filter(Boolean);
  const [bValue, bUnit] = b.created_at.split(/(\d+)/).filter(Boolean);
  const unitWeights = { s: 1, m: 2, h: 3, d: 4, w: 5, mo: 6, y: 7 };
  if (unitWeights[aUnit] < unitWeights[bUnit]) {
    return -1;
  } else if (unitWeights[aUnit] > unitWeights[bUnit]) {
    return 1;
  } else {
    return parseInt(aValue) - parseInt(bValue);
  }
};

export const getIdByUrl = (url, arrayOfObjects) => {
  for (const obj of arrayOfObjects) {
    if (obj.url === url) {
      return obj.id;
    }
  }
  return false;
};

/**
 * Creates a debounced function that delays invoking the provided function until after `timeout` milliseconds have elapsed since the last time it was invoked.
 * @param {Function} func - The function to debounce.
 * @param {number} [timeout=600] - The number of milliseconds to delay.
 * @returns {Function} The debounced function.
 */
export function debounce(func, timeout = 500) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export async function convertFilesToBase64(files) {
  const promises = files.map((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        const mimeType = reader.result.split(',')[0].match(/:(.*?);/)[1];
        const dataUrl = `data:${mimeType};base64,${base64String}`;
        resolve({
          name: file.name,
          data: dataUrl,
          type: file.type,
          size: file.size,
        });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  });

  try {
    const results = await Promise.all(promises);
    return results;
  } catch (error) {
    throw error;
  }
}

/**
 * Checks if the provided text contains a valid URL or a valid YouTube video link or valid HTML tag.
 * Utilizes regular expressions to check for a valid URL pattern, a valid YouTube video link pattern or HTML tag.
 * @param {string} text - The text to be checked.
 * @returns {string} Returns a message indicating if the text contains a valid YouTube video link, a valid link but not a YouTube video link, or HTML tag or just normal text.
 */
export function checkCommentType(text) {
  // Regular expression pattern for a valid URL
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  // Regular expression pattern for a valid YouTube video link
  const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;
  // Regular expression pattern for a valid HTML tag
  const htmlTagRegex = /<("[^"]*"|'[^']*'|[^'">])*>/;
  // Check if the text matches a valid URL pattern
  const isURL = urlPattern.test(text);
  // Check if the text matches a valid HTML pattern
  const isHtml = htmlTagRegex.test(text);
  // Check if the text matches a valid YouTube video link pattern
  const isYouTubeLink = youtubePattern.test(text);
  if (isURL) {
    if (isYouTubeLink) {
      return 'youtube_video';
    }
    return 'link';
  }
  if (isHtml) {
    return 'html';
  } else {
    return 'normal_text';
  }
}

/**
 * Replaces URLs not within anchor tags in a string with proper anchor tags.
 * Uses a regular expression to identify URLs within and outside of anchor tags.
 * Replaces URLs outside anchor tags with anchor tags in the input string.
 * @param {string} inputString - The string containing URLs to be embedded in anchor tags.
 * @returns {string} Returns the input string with URLs outside anchor tags replaced by proper anchor tags.
 */
export function embedLinksInATags(inputString) {
  // Regular expression to identify URLs
  const urlRegex =
    /<a\s+(?:[^>]*?\s+)?href=(?:"([^"]*)"|'([^']*)')(?:\s+[^>]*?\s*)?>(https?:\/\/[^\s<]*)<\/a>|(?<!<a\s+(?:[^>]*?\s+)?href=(?:"[^"]*"|'[^']*')(?:\s+[^>]*?\s*)?>)((?:https?:\/\/|www\.)[^\s<]+)/gi;

  // Function to replace URLs not within anchor tags with proper anchor tags
  const replacedString = inputString.replace(
    urlRegex,
    (match, group1, group2, group3, group4) => {
      // If the URL is already within an anchor tag's href attribute, return the match without modification
      if (group1 !== undefined || group2 !== undefined) {
        return match;
      } else {
        // Replace the URL with the anchor tag
        const url = group3 || group4 || match;
        const fullURL = url.startsWith('www.') ? `https://${url}` : url;
        return `<a target="_blank" href="${fullURL}">${url}</a>`;
      }
    }
  );

  return replacedString;
}

/**
 * Embeds actual text (what user has written) within commented-out markers.
 * @param {string} actualText - The actual text (written content) to be embedded within commented-out markers.
 * @param {string} text - The full text string (containing the tags) where the actual text will be embedded.
 * @returns {string} Returns the text string with the actual text embedded within HTML markers.
 */
export function embedActualText(actualText, text) {
  const encodedString = actualText.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  const result = text.split(encodedString);
  const embed = '{/*' + encodedString + '*/}';
  result.splice(1, 0, embed);
  // const index = result.indexOf(embed);
  // return result[index];
  return result.join('');
}

// check if an email contains an html tag
export const checkHtmlTag = (value) => {
  const htmlString = value;
  const regex = /<html\b[^>]*>[\s\S]*<\/html>/i;
  const tagExists = regex.test(htmlString);
  return tagExists;
};

export const getStartWizard = (
  data,
  setWizard,
  setDetail,
  setUnlockPremium
) => {
  if (Object.keys(data).length > 0) {
    setUnlockPremium(true);
    if (
      data.focus === null ||
      (data.team_size === null && data.project_count === null) ||
      data.company_name === null
    ) {
      setWizard(0);
      if (data.focus === null) {
        setDetail(0);
      } else if (data.team_size === null && data.project_count === null) {
        setDetail(1);
      } else if (data.company_name === null) {
        setDetail(2);
      }
    } else if (data?.websites === null) {
      setWizard(1);
      setDetail(3);
    } else if (data?.team_members === null) {
      setWizard(2);
      setDetail(3);
    } else if (data.integrations === null) {
      setDetail(3);
      setWizard(3);
    } else {
      setWizard(4);
      setDetail(3);
    }
  }
};

export function isThreeDaysAfter(dateString) {
  const givenDate = new Date(dateString);
  const currentDate = new Date();
  const threeDaysLater = new Date(givenDate.setDate(givenDate.getDate() + 3));

  // Normalize the time part of the dates to ensure we're only comparing the dates
  threeDaysLater.setHours(0, 0, 0, 0);
  givenDate.setHours(0, 0, 0, 0);

  return threeDaysLater.getTime() < currentDate.getTime();
}

export const dayLeftForTrial = (dateString) => {
  const currentDate = new Date();
  const activationDate = new Date(dateString);
  const daysLeftOfTrial =
    14 - Math.floor((currentDate - activationDate) / (1000 * 3600 * 24));
  return daysLeftOfTrial;
};
export const dayUsedForTrial = (dateString) => {
  const currentDate = new Date();
  const activationDate = new Date(dateString);
  const daysLeftOfTrial = Math.floor(
    (currentDate - activationDate) / (1000 * 3600 * 24)
  );
  return daysLeftOfTrial;
};

export function* getSelectedWorkspace() {
  const getSelectedWorkspace = (state) => state.workspace.selectedWorkspace;
  let selectedWorkspace = yield select(getSelectedWorkspace);

  if (selectedWorkspace === undefined || selectedWorkspace === '') {
    selectedWorkspace = JSON.parse(localStorage.getItem('selectedWorkspace'));
  }

  return selectedWorkspace;
}

export function formatNumber(num) {
  if (num < 1000) {
    return num.toString();
  } else if (num < 1000000) {
    // Format number to one decimal place for thousands and round it
    return `${(Math.round(num / 100) / 10).toFixed(1)}k`;
  }
}

export const showRegisterModalOnCollaborate = (
  token,
  enterpriseClientId,
  godaddy_username
) => {
  if (
    !token &&
    !enterpriseClients.includes(enterpriseClientId) &&
    godaddy_username === null
  ) {
    return true;
  }
  return false;
};
