import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppSelector } from './redux';

const useUpgradePlanEffect = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { token } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (
      !token ||
      location.pathname === '/get-started' ||
      location.pathname === '/register'
    )
      return;
    const plan = searchParams.get('plan');
    const users = searchParams.get('users');
    const billed = searchParams.get('billed');
    const coupon = searchParams.get('coupon');
    const planSearch = {
      plan,
      users,
      billed,
      coupon,
    };
    if (plan && users && billed && location.pathname !== '/get-started') {
      dispatch({ type: 'SAVE_PLAN_SEARCH', planSearch });
      dispatch({ type: 'HANDLE_PLANS_DIALOG', params: true });
    }
  }, [searchParams, token, location.pathname]);
};

export default useUpgradePlanEffect;
