import React, { useCallback, useMemo, useState } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
// Component
import { useDispatch, useSelector } from 'react-redux';
import PlanUpgrading from '../plans/PlansUpgrading';
// Styling
import { Badge, CircularProgress, Slide } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  BiCog,
  BiDetail,
  BiDollarCircle,
  BiEnvelope,
  BiHelpCircle,
  BiPowerOff,
  BiTimeFive,
} from 'react-icons/bi';
import { BsBarChart, BsGrid } from 'react-icons/bs';
import {
  FaAngleLeft,
  FaAngleRight,
  FaBullhorn,
  FaRegBell,
  FaRegBellSlash,
} from 'react-icons/fa';
import { IoAnalytics } from 'react-icons/io5';
import { LuUsers, LuWorkflow } from 'react-icons/lu';
import { PiKanban } from 'react-icons/pi';
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarHeader,
  SubMenu,
} from 'react-pro-sidebar';
import { Avatar } from 'ui/components/Avatar';
import Button from 'ui/components/Button';
import { Progress } from 'ui/components/Progress';
import TimerCollection from '../TimerCollection';
import FrillWidget from './FrillWidget';
import Notifications from './Notifications';
import WorkspaceNav from './workspace-nav';
// Utils
import { AdminRoles } from 'utils/constants';
// Actions

import { skipToken } from '@reduxjs/toolkit/query';
import { useAppSelector } from '@src/hooks/redux';
import { useGetNotLoggedEntriesQuery } from '@src/store/api/endpoints/time-entries';
import { MAKE_SIDENAV_FALSE } from 'actions/global_settings';
import {
  NEW_TASK_SELECTED,
  NEW_WEBSITE_SELECTED,
  SEND_MESSAGE,
  SEND_MESSAGE_WITHOUT_POST,
} from 'actions/tasks';
import {
  COLLAPSE_SIDE_NAV,
  HANDLE_PLANS_DIALOG,
  OPEN_CLOSE_NOTIFICATIONS_MODAL,
} from 'actions/user';
import clsx from 'clsx';
import { useLanguage } from 'translations';
import { HoverCard } from 'ui';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from 'ui/components/Sheet';
import { cn } from 'utils/cn';
import { useAuth } from '../../hooks/useAuth';
import GotoChromeButtonV1 from '../GoToChromeButtonV1';
const classes = (theme) => ({
  notifications: {
    display: 'flex',
  },

  bullIcon: {
    opacity: '1',
  },
  userAvatar: {
    width: '80px',
    height: '80px',
    borderRadius: '8px',
  },
  loaderContainer: {
    textAlign: 'center',
  },
  loader: {
    color: '#A4ABC5',
    '&:hover': {
      color: '#279AF1',
    },
  },
});

const SiteNav = ({ classes }) => {
  const { logoutUser } = useAuth();
  const [searchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openWidget, setOpenWidget] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const timers = useAppSelector((state) => state.time_entries_new.timers);
  const { selectedWorkspace: workspaceSelected } = useAppSelector(
    (state) => state.workspace
  );
  const { data: notLoggedTimeEntries, isLoading: loadingTimeEntries } =
    useGetNotLoggedEntriesQuery(workspaceSelected?.id ?? skipToken);

  // Redux state
  const {
    selectedIndex,
    user,
    token,
    notificationDetails,
    moreUnreadWebRecords,
    moreReadWebRecords,
    unreadCount,
    muteState,
    readNotifications,
    unreadNotifications,
    readNotificationsOffsetCounter,
    unreadNotificationsOffsetCounter,
    isNotificationsModalOpen,
    navInCollaborate,
    menuCollapse,
    selectedTimerEntry,
    currentTimerTaskId,
    timerPlay,
    isPlansDialogOpened,
    selectedWorkspace,
    isLoading,
    updatingNotification,
  } = useSelector((state) => ({
    selectedIndex: state.user.selectedIndex,
    user: state.user.userData,
    token: state.user.token,
    notificationDetails: state.user.notificationDetails,
    moreUnreadWebRecords: state.user.moreUnreadWebRecords,
    moreReadWebRecords: state.user.moreReadWebRecords,
    unreadCount: state.user.unreadCount,
    muteState: state.user.muteState,
    readNotifications: state.user.readNotifications,
    unreadNotifications: state.user.unreadNotifications,
    readNotificationsOffsetCounter: state.user.readNotificationsOffsetCounter,
    unreadNotificationsOffsetCounter:
      state.user.unreadNotificationsOffsetCounter,
    isNotificationsModalOpen: state.user.isNotificationsModalOpen,
    navInCollaborate: state.collaborate.navInCollaborate,
    menuCollapse: state.user.menuCollapse,
    isPlansDialogOpened: state.user.isPlansDialogOpened,
    selectedWorkspace: state.workspace.selectedWorkspace,
    isLoading: state.user.isLoading,
    updatingNotification: state.user.updatingNotification,
    //Timer Entries
    selectedTimerEntry: state.time_entries.selectedTimerEntry,
    currentTimerTaskId: state.time_entries.currentTimerTaskId,
    timerPlay: state.time_entries.timerPlay,
  }));

  const { currentLanguage, changeLanguage, languages } = useLanguage();

  const isUserAdmin = () => {
    return AdminRoles.indexOf(selectedWorkspace.role) !== -1;
  };

  const menuIconClick = () => {
    dispatch({ type: COLLAPSE_SIDE_NAV });
  };

  const handleClickWidget = () => {
    setOpenWidget(!openWidget);
  };

  const handleCloseWidget = () => {
    setOpenWidget(false);
  };

  const Transition = React.forwardRef((props, ref) => (
    <Slide direction="right" ref={ref} {...props} />
  ));

  const openCloseNotificationModal = useCallback(
    (param) => {
      dispatch({ type: OPEN_CLOSE_NOTIFICATIONS_MODAL, param });
    },
    [dispatch]
  );

  const selectTask = useCallback(
    (taskId) => {
      dispatch({ type: NEW_TASK_SELECTED, taskId });
    },
    [dispatch]
  );

  const websiteSelected = useCallback(
    (websiteID) => {
      dispatch({ type: NEW_WEBSITE_SELECTED, websiteId: websiteID });
    },
    [dispatch]
  );

  const sendMessage = useCallback(
    (params) => {
      dispatch({ type: SEND_MESSAGE, params });
    },
    [dispatch]
  );

  const sendMessageWithoutPosting = useCallback(
    (params) => {
      dispatch({ type: SEND_MESSAGE_WITHOUT_POST, params });
    },
    [dispatch]
  );

  const handleClickIncreaseResources = () => {
    if (user.plan.from_free_plan) {
      dispatch({ type: HANDLE_PLANS_DIALOG, params: true });
    } else {
      if (user.plan.upsell_url) {
        window.open(user.plan.upsell_url);
      } else {
        window.open(`https://atarim.io/upgrade/`);
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBillingClick = () => {
    if (user.plan.from_free_plan) {
      dispatch({ type: MAKE_SIDENAV_FALSE });
      navigate('/billing');
    } else {
      window.open(
        `${import.meta.env.VITE_APP_MAIN_WEBSITE_URL}/account/?parameter_1=${token}&parameter_2=${
          user.parameter_2
        }&parameter_3=${user.parameter_3}&parameter_4=${user.parameter_4}${
          user.source ? `&_from=${user.source}` : ''
        }`
      );
    }
  };

  const taskParams = useMemo(() => {
    const websiteId = searchParams.get('website') || searchParams.get('item');

    const params = new URLSearchParams();

    if (websiteId) params.append('website', websiteId);

    return params;
  }, [searchParams, location.search]);

  return (
    <>
      <div
        id="sitenav-header"
        className={cn(
          'sitenav-header hidden md:block',
          !navInCollaborate && location.pathname.includes('/collaborate')
            ? 'closed'
            : 'open'
        )}
      >
        <ProSidebar collapsed={menuCollapse}>
          <div>
            <SidebarHeader>
              <div className="logotext">
                {menuCollapse ? (
                  <img
                    className="logo collapsed"
                    alt="Atarim Logo"
                    src={
                      '/assets/New_logo_request_v4_without_padding_white_with_purple_accent.svg'
                    }
                  />
                ) : (
                  <img
                    className="logo"
                    alt="Atarim Logo"
                    src={'/assets/9304_New_logo_request_v5c.svg'}
                  />
                )}
              </div>
              {!location.pathname.includes('/collaborate') && (
                <div className="closemenu" onClick={menuIconClick}>
                  {menuCollapse ? <FaAngleRight /> : <FaAngleLeft />}
                </div>
              )}
            </SidebarHeader>
            <SidebarContent>
              <Menu>
                <li
                  className="pro-menu-item workspace-nav-item"
                  data-testid="NavWorkspaces"
                >
                  <div
                    className={`pro-inner-item transition ${menuCollapse && 'justify-center'}`}
                  >
                    <div className={`relative h-5 ${!menuCollapse && 'mr-2'}`}>
                      {/* {selectedWorkspace.project_count > 0 ? (
                        <span className="absolute -top-0.5 left-[15px] z-[1] h-2 w-2 rounded-[50%] bg-[#E72D67] outline outline-[2px] outline-[#2f326a]"></span>
                      ) : null} */}
                      <Avatar
                        src={selectedWorkspace?.icon}
                        alt={selectedWorkspace?.name}
                        id={selectedWorkspace?.id}
                        size="fit"
                        className="text-[8px]"
                        title={selectedWorkspace.name}
                      />
                    </div>
                    {!menuCollapse && (
                      <span className="workspace-name">
                        {selectedWorkspace?.name}
                      </span>
                    )}
                  </div>
                  <div className="pro-inner-list-item popper-element">
                    <WorkspaceNav />
                  </div>
                </li>
                <MenuItem
                  icon={<BsGrid />}
                  onClick={() => {
                    navigate('/');
                  }}
                  active={location.pathname === '/'}
                  selected={selectedIndex === 8}
                  className="tooltip"
                >
                  <span className="menu-item-name">Projects</span>
                  <span className="tooltip-content">Projects</span>
                </MenuItem>
                <MenuItem
                  icon={<BiEnvelope />}
                  onClick={() => {
                    navigate(`/tasks?${taskParams.toString()}`);
                  }}
                  active={location.pathname === '/tasks'}
                  className="tooltip"
                  data-testid="NavInbox"
                >
                  <span className="menu-item-name">Inbox </span>
                  <span className="tooltip-content">Inbox </span>
                </MenuItem>
                <MenuItem
                  icon={<PiKanban />}
                  onClick={() => {
                    const newParams = new URLSearchParams(
                      taskParams.toString()
                    );
                    newParams.append('board', 'status');
                    navigate(`/board?${newParams.toString()}`);
                  }}
                  active={location.pathname === '/board'}
                  selected={selectedIndex === 3}
                  className="tooltip"
                  data-testid="NavBoards"
                >
                  <span className="menu-item-name">Boards</span>
                  <span className="tooltip-content">Boards</span>
                </MenuItem>

                <MenuItem
                  icon={<BiDetail />}
                  onClick={() => {
                    navigate('/forms');
                  }}
                  active={location.pathname === '/forms'}
                  selected={selectedIndex === 7}
                  className="tooltip"
                >
                  <span className="menu-item-name">Forms</span>
                  <span className="tooltip-content">Forms</span>
                </MenuItem>
              </Menu>

              <Menu iconShape="" className="item-2">
                <MenuItem
                  icon={<LuWorkflow />}
                  onClick={() => {
                    navigate('/workflows');
                  }}
                  active={location.pathname === '/workflows'}
                  selected={selectedIndex === 11}
                  className="tooltip icon-workflows"
                  data-testid="NavWorkflows"
                >
                  <span className="menu-item-name">Workflows</span>
                  <span className="tooltip-content">Workflows</span>
                  <span className="pill-new">New</span>
                </MenuItem>
                <HoverCard openDelay={100} closeDelay={200}>
                  <HoverCard.Trigger asChild>
                    <MenuItem
                      active={location.pathname === '/time-entries'}
                      selected={selectedIndex === 10}
                      icon={<BiTimeFive />}
                      onClick={() => {
                        navigate('/time-entries');
                      }}
                    >
                      <span className="menu-item-name">
                        Time Entries
                        {notLoggedTimeEntries &&
                        notLoggedTimeEntries.length > 0 &&
                        !loadingTimeEntries ? (
                          <span className="bg-destructive ml-1 h-4 min-w-4 justify-center rounded-full px-1.5 text-sm font-medium leading-none text-white">
                            {notLoggedTimeEntries.length}
                          </span>
                        ) : null}
                      </span>
                    </MenuItem>
                  </HoverCard.Trigger>
                  <HoverCard.Content side="right" className="w-fit">
                    <TimerCollection />
                  </HoverCard.Content>
                </HoverCard>

                <MenuItem
                  icon={<IoAnalytics />}
                  onClick={() => {
                    navigate('/dashboard');
                  }}
                  active={location.pathname === '/dashboard'}
                  selected={selectedIndex === 10}
                  className="tooltip"
                >
                  <span className="menu-item-name">Analytics</span>
                  <span className="tooltip-content">Analytics</span>
                </MenuItem>
                {isUserAdmin() ? (
                  <MenuItem
                    icon={<LuUsers />}
                    onClick={() => {
                      navigate('/user');
                    }}
                    active={location.pathname === '/users'}
                    selected={selectedIndex === 5}
                    className="tooltip"
                  >
                    <span className="menu-item-name">People</span>
                    <span className="tooltip-content">People</span>
                  </MenuItem>
                ) : (
                  <></>
                )}
                {isUserAdmin() ? (
                  <MenuItem
                    icon={<BiCog />}
                    onClick={() => {
                      navigate('/settings');
                    }}
                    active={location.pathname === '/settings'}
                    selected={selectedIndex === 4}
                    className="tooltip"
                    data-testid="NavSettings"
                  >
                    <span className="menu-item-name">Settings</span>
                    <span className="tooltip-content">Settings</span>
                  </MenuItem>
                ) : (
                  <></>
                )}
                {user?.user_permissions?.can_impersonate ? (
                  <MenuItem
                    icon={<LuUsers />}
                    onClick={() => {
                      navigate('/impersonate-user');
                    }}
                    active={location.pathname === '/impersonate-user'}
                    selected={selectedIndex === 4}
                    className="tooltip"
                  >
                    <span className="menu-item-name">Impersonate</span>
                    <span className="tooltip-content">Impersonate</span>
                  </MenuItem>
                ) : (
                  <></>
                )}
              </Menu>

              <Menu iconShape="" className="item-3">
                {isUserAdmin() ? (
                  <MenuItem
                    icon={<BiDollarCircle />}
                    onClick={handleBillingClick}
                    className="tooltip"
                  >
                    <span className="menu-item-name">Billing</span>
                    <span className="tooltip-content">Billing</span>
                  </MenuItem>
                ) : (
                  <></>
                )}
                <MenuItem
                  icon={<BiHelpCircle />}
                  onClick={() => window.open('https://support.atarim.io/')}
                  className="tooltip"
                  data-testid="NavHelp"
                >
                  <span className="menu-item-name">Help</span>
                  <span className="tooltip-content">Help</span>
                </MenuItem>

                <SubMenu
                  title={'Resources'}
                  label="Resources"
                  icon={<BsBarChart />}
                >
                  <Menu iconShape="" className="item-4">
                    {isLoading ? (
                      <div className={classes.loaderContainer}>
                        <CircularProgress
                          className={classes.loader}
                          size={20}
                        />
                      </div>
                    ) : user.plan ? (
                      <>
                        <div className="progress-wrapper">
                          <div className="progress-heading">
                            <span>
                              <b>Projects</b>
                            </span>
                            <span>
                              {user.plan.websites > 0
                                ? `${
                                    user.plan.websites - user.limit.websites
                                  }/${user.plan.websites}`
                                : user.plan.websites === -1
                                  ? `${user.limit.websites}/Unlimited`
                                  : `Maxed Out`}
                            </span>
                          </div>
                          <Progress
                            variant="primary"
                            progress={
                              user.plan.websites === -1
                                ? 5
                                : ((user.plan.websites - user.limit.websites) /
                                    user.plan.websites) *
                                  100
                            }
                          />
                        </div>
                        <div className="progress-wrapper">
                          <div className="progress-heading">
                            <span>
                              <b>Storage</b>
                            </span>
                            <span>
                              {user.limit.storage_limit > 0
                                ? `${(
                                    user.limit.storage_used_bytes / 1073741824
                                  ).toFixed(2)}/${user.limit.storage_limit}GB`
                                : `${user.limit.storage_limit}GB`}
                            </span>
                          </div>
                          <Progress
                            variant="primary"
                            progress={
                              (user.limit.storage_used_bytes /
                                1073741824 /
                                user.limit.storage_limit) *
                              100
                            }
                          />
                        </div>
                        {isUserAdmin() && (
                          <>
                            <div className="progress-wrapper">
                              <div className="progress-heading">
                                <span>
                                  <b>Team Members</b>
                                </span>
                                <span>
                                  {user.plan.users > 0
                                    ? `${user.plan.users - user.limit.users}/${
                                        user.plan.users
                                      }`
                                    : user.plan.users === -1
                                      ? `${user.limit.users}/Unlimited`
                                      : `Maxed Out`}
                                </span>
                              </div>
                              <Progress
                                variant="primary"
                                progress={
                                  ((user.plan.users - user.limit.users) /
                                    user.plan.users) *
                                  100
                                }
                              />
                            </div>
                            <div className="progress-wrapper">
                              <div className="progress-heading">
                                <span>
                                  <b>Workspaces</b>
                                </span>
                                <span>
                                  {user.plan.workspace > 0
                                    ? `${
                                        user.plan.workspace -
                                        user.limit.workspaces
                                      }/${user.plan.workspace}`
                                    : user.plan.workspace === -1
                                      ? `${user.limit.workspaces}/Unlimited`
                                      : `Maxed Out`}
                                </span>
                              </div>
                              <Progress
                                variant="primary"
                                progress={
                                  ((user.plan.workspace -
                                    user.limit.workspaces) /
                                    user.plan.workspace) *
                                  100
                                }
                              />
                            </div>
                            <div className="button-nav-container">
                              <button
                                className="at-cs-btn btn-green w-100"
                                onClick={() => handleClickIncreaseResources()}
                                disabled={user.role === 'contributor'}
                              >
                                Increase Resources
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    ) : null}
                  </Menu>
                </SubMenu>
              </Menu>
            </SidebarContent>
          </div>
          <SidebarContent className="footer">
            <Sheet open={isNotificationsModalOpen} modal={false}>
              <SheetContent
                onClose={() => openCloseNotificationModal(false)}
                side={'left'}
                className={`${menuCollapse ? 'left-[70px]' : 'left-[227px]'} duration-7 flex flex-col p-0 transition-all`}
              >
                <SheetHeader className="px-2 pt-2 md:px-4">
                  <SheetTitle>Notifications</SheetTitle>
                </SheetHeader>
                <Notifications
                  moreUnreadWebRecords={moreUnreadWebRecords}
                  moreReadWebRecords={moreReadWebRecords}
                  notificationDetails={notificationDetails}
                  muteState={muteState}
                  readNotifications={readNotifications}
                  unreadNotifications={unreadNotifications}
                  readNotificationsOffsetCounter={
                    readNotificationsOffsetCounter
                  }
                  unreadNotificationsOffsetCounter={
                    unreadNotificationsOffsetCounter
                  }
                  updatingNotification={updatingNotification}
                  selectTask={selectTask}
                  websiteSelected={websiteSelected}
                />
              </SheetContent>
            </Sheet>
            <Sheet open={openWidget} modal={false}>
              <SheetContent
                onClose={handleCloseWidget}
                side={'left'}
                className={`${menuCollapse ? 'left-[70px]' : 'left-[227px]'} z-[999] w-fit max-w-fit overflow-hidden bg-[#2F326A] p-0 text-white transition-all duration-75 sm:max-w-fit`}
              >
                <SheetHeader>
                  <SheetTitle className="bg-[#2F326A] px-2 pt-2 text-white">
                    What's New
                  </SheetTitle>
                  <FrillWidget />
                </SheetHeader>
              </SheetContent>
            </Sheet>

            <GotoChromeButtonV1 menuCollapse={menuCollapse} />

            <Menu>
              {user.role !== 'collaborator' ? (
                <>
                  <MenuItem
                    className="tooltip"
                    title="Notifications"
                    onClick={() => {
                      openCloseNotificationModal(!isNotificationsModalOpen);
                      handleCloseWidget();
                    }}
                  >
                    <span className="tooltip-content">Notifications</span>
                    <Badge
                      badgeContent={muteState ? 0 : unreadCount}
                      className="notification-counter"
                    >
                      {!muteState ? (
                        <FaRegBell
                          size={20}
                          className={cn(classes.bullIcon, 'mr-2')}
                        />
                      ) : (
                        <FaRegBellSlash
                          size={30}
                          className={classes.bullIcon}
                        />
                      )}
                    </Badge>
                    <span className={classes.whatsNewText}>Notifications</span>
                  </MenuItem>
                  <MenuItem
                    className="tooltip"
                    title="What's New"
                    onClick={() => {
                      handleClickWidget(true);
                      openCloseNotificationModal(false);
                    }}
                  >
                    <span className="tooltip-content">What's New</span>
                    <span className="notification-counter">
                      <FaBullhorn
                        size={20}
                        className={cn(classes.bullIcon, 'mr-2')}
                      />
                      <span className={classes.whatsNewText}>What's New</span>
                    </span>
                  </MenuItem>
                  {/* <MenuItem
                    onClick={() => {
                      changeLanguage(currentLanguage === 'en' ? 'fr-CA' : 'en');
                    }}
                  >
                    <span className="tooltip-content">
                      {languages[currentLanguage]}
                    </span>
                    <span className="notification-counter">
                      <Globe2
                        size={20}
                        className={cn(classes.bullIcon, 'mr-2')}
                      />
                      <span className={classes.whatsNewText}>
                        {languages[currentLanguage]}
                      </span>
                    </span>
                  </MenuItem> */}
                </>
              ) : (
                <></>
              )}
              <div className="user-details flex gap-2">
                <Avatar
                  src={user.avatar}
                  borderRadius="lg"
                  size="fit"
                  className="max-h-12 max-w-12"
                  id={user.normal_id}
                  alt={user.name}
                  onClick={handleClick}
                  title={user.name}
                />

                <span className="username">{user.name}</span>
                <div className="pro-inner-list-item popper-element">
                  <div className="popper-inner menu-user-popout">
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      disableAutoFocusItem
                      disablePortal
                    >
                      <MenuItem
                        onClick={() => {
                          navigate('/user');
                        }}
                      >
                        <Avatar
                          src={user.avatar}
                          borderRadius="lg"
                          size="lg"
                          className="h-12 w-12"
                          id={user.normal_id}
                          alt={user.name}
                          onClick={handleClick}
                          title={user.name}
                        />
                        <div className="ml-2 flex flex-col gap-1">
                          <span className="username font-medium leading-none text-black/80">
                            {user.name}
                          </span>
                          <Link
                            to={`/user`}
                            className="at-ch-user-view-profile leading-none"
                            title="My Profile"
                          >
                            View Profile
                          </Link>
                        </div>
                      </MenuItem>
                      <hr className="at-ch-user-details-group-divider" />
                      <MenuItem onClick={handleClose}>
                        <Button
                          className="w-full border-0 bg-transparent outline-none"
                          size="sm"
                          leftIcon={<BiPowerOff size={24} />}
                          onClick={() => {
                            logoutUser();
                          }}
                        >
                          Logout
                        </Button>
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
      <footer className="w-100 fixed bottom-0 z-[100] flex h-16 flex-row justify-between bg-[#2F326A] px-2 text-[10px] md:hidden">
        <span
          className={clsx(
            'flex flex-col items-center justify-center gap-1 p-2',
            location.pathname === '/settings' ? 'text-tertiary' : 'text-white'
          )}
          onClick={() => {
            navigate('/settings?tab_flag=workspace');
          }}
          active={location.pathname === '/tasks'}
          selected={selectedIndex === 2}
        >
          <Avatar
            src={selectedWorkspace?.icon}
            alt={selectedWorkspace?.name}
            id={selectedWorkspace?.id}
            size="xs"
            className="my-0.5"
            title={selectedWorkspace.name}
          />
          Workspaces
        </span>
        <span
          className={clsx(
            'flex flex-col items-center justify-center gap-1 p-2',
            location.pathname === '/tasks' ? 'text-tertiary' : 'text-white'
          )}
          onClick={() => {
            navigate(`/tasks?${taskParams.toString()}`);
          }}
          active={location.pathname === '/tasks'}
          selected={selectedIndex === 2}
        >
          <BiEnvelope size={24} /> Inbox
        </span>

        <span
          className="flex flex-col items-center justify-center p-2 text-white"
          onClick={() => {
            navigate('/user');
          }}
        >
          <Avatar
            src={user.avatar}
            borderRadius="lg"
            size="lg"
            id={user.normal_id}
            alt={user.name}
            onClick={handleClick}
            title={user.name}
          />
        </span>
        <span
          className={clsx(
            'flex flex-col items-center justify-center gap-1 p-2',
            location.pathname === '/board' ? 'text-tertiary' : 'text-white'
          )}
          onClick={() => {
            const newParams = new URLSearchParams(taskParams.toString());
            newParams.append('board', 'status');
            navigate(`/board?${newParams.toString()}`);
          }}
          active={location.pathname === '/board'}
          selected={selectedIndex === 3}
        >
          <PiKanban size={24} /> Boards
        </span>

        {user.role !== 'collaborator' && (
          <span
            className="flex flex-col items-center justify-center gap-1 p-2 text-white"
            title="Notifications"
            onClick={() =>
              openCloseNotificationModal(!isNotificationsModalOpen)
            }
          >
            {!muteState && (
              <Badge badgeContent={muteState ? 0 : unreadCount} className=" ">
                {!muteState ? (
                  <FaRegBell size={20} className="my-0.5" />
                ) : (
                  <FaRegBellSlash size={30} className={classes.bullIcon} />
                )}
              </Badge>
            )}
            Notifications
          </span>
        )}
      </footer>
      {isPlansDialogOpened && <PlanUpgrading closeParentDialogs={() => {}} />}
    </>
  );
};

export default withStyles(classes)(SiteNav);
