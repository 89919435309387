import { useAppDispatch, useAppSelector } from "@src/hooks/redux";
import { useAuth } from "@src/hooks/useAuth";
import { FIXME } from "@src/types";
import { HANDLE_PLANS_DIALOG } from "actions/user";
import { CreditCard, LogOut, Rocket, SquareUser } from "lucide-react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Avatar, Button, DropdownMenu } from "ui";
import { useSidebar } from "ui/components/Sidebar";
import { cn } from 'utils/cn';
import { AdminRoles } from "utils/constants";

const UserSettings = () => {
    const selectedWorkspace = useAppSelector((state: FIXME) => state.workspace.selectedWorkspace);
    const isUserAdmin = () => {
        return AdminRoles.indexOf(selectedWorkspace.role) !== -1;
    };

    const { logoutUser } = useAuth();
    const {
        open,
    } = useSidebar();
    const { userData } = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();

    const handleNavigateToUpgrade = () => {
        if (userData.plan.from_free_plan && userData.subscription) {
            dispatch({ type: HANDLE_PLANS_DIALOG, params: true });
        } else {
            const upgradeUrl =
                userData.plan.upsell_url ||
                `${import.meta.env.VITE_APP_MAIN_WEBSITE_URL}/upgrade?_from=${userData.source}`;
            window.open(upgradeUrl);
        }
    };

    const isFreePlan = useMemo(() => {
        const plan: string = userData.plan.title;
        if (!plan) return false;
        if (plan.toLowerCase().includes('free') || plan.toLowerCase().includes('trial')) {
            return true;
        } else {
            return false;
        }
    }, [userData.plan.title]);

    return (
        <DropdownMenu>
            <DropdownMenu.Trigger asChild>
                <Button variant="ghost" size="icon" className={cn(open ? 'h-10 pl-1 pr-2 w-full gap-2' : 'w-9 h-9')}>
                    <Avatar size="fit" src={userData.avatar} email={userData.email} className={cn('relative flex shrink-0 overflow-hidden rounded-md', open ? 'w-8 h-8' : 'w-9 h-9')}
                        title={userData.name} alt={userData.name} id={userData.id} />
                    {open &&
                        <div className="grid flex-1 text-left text-sm leading-tight">
                            <span className="font-medium truncate">{userData.name}</span>
                        </div>}
                </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content side="right" align="end" className="min-w-[250px]">
                <DropdownMenu.Label className="flex gap-2 px-1">
                    <Avatar src={userData.avatar} email={userData.email} className="h-8 w-8 rounded-md" title={userData.name} alt={userData.name} id={userData.id} />
                    <div className="grid flex-1 text-left text-sm leading-tight">
                        <span className="font-medium truncate max-w-[180px]">{userData.name}</span>
                        <span className="text-xs text-gray-400 truncate font-normal max-w-[180px]">{userData.email}</span>
                    </div>
                </DropdownMenu.Label>
                <DropdownMenu.Separator />
                <DropdownMenu.Group>
                    <DropdownMenu.Item asChild>
                        <Link
                            to={`/user`}
                        >
                            <SquareUser size={18} /><span>View Profile</span>
                        </Link>
                    </DropdownMenu.Item>
                    {isFreePlan && isUserAdmin() && (
                        <DropdownMenu.Item onClick={handleNavigateToUpgrade}>
                            <Rocket size={18} /><span>Upgrade Account</span>
                        </DropdownMenu.Item>
                    )}
                    {isUserAdmin() ? (
                        <DropdownMenu.Item asChild>
                            <Link
                                to={`/billing`}>
                                <CreditCard size={18} /><span>Billing</span>
                            </Link>
                        </DropdownMenu.Item>
                    ) : null}
                </DropdownMenu.Group>
                <DropdownMenu.Separator />
                <DropdownMenu.Item onClick={() => {
                    logoutUser();
                }}>
                    <LogOut size={18} /><span>Log Out</span>
                </DropdownMenu.Item>
            </DropdownMenu.Content >
        </DropdownMenu >
    );
};
export default UserSettings;