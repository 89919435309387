import * as React from 'react';
import { cn } from 'utils/cn';
import Dialog from '../Dialog';
import Drawer from '../Drawer';
import { useResponsiveDialog } from './responsive-dialog-provider';

interface BaseProps {
  children: React.ReactNode;
}

export interface RootResponsiveDialog extends BaseProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export interface ResponsiveDialog extends BaseProps {
  className?: string;
  asChild?: true;
  [key: string]: any;
}

const ResponsiveDialogRoot = ({
  children,
  open,
  onOpenChange,
  ...props
}: RootResponsiveDialog) => {
  const showDrawer = useResponsiveDialog();
  const ResponsiveDialog = showDrawer ? Drawer : Dialog;

  return (
    <ResponsiveDialog
      open={open}
      onOpenChange={onOpenChange}
      direction="bottom"
      {...props}
    >
      {children}
    </ResponsiveDialog>
  );
};

const ResponsiveDialogTrigger = ({
  className,
  children,
  asChild,
  ...props
}: ResponsiveDialog) => {
  const showDrawer = useResponsiveDialog();
  const ResponsiveDialogTrigger = showDrawer ? Drawer.Trigger : Dialog.Trigger;

  return (
    <ResponsiveDialogTrigger className={className} {...props}>
      {children}
    </ResponsiveDialogTrigger>
  );
};

const ResponsiveDialogClose = ({
  className,
  children,
  ...props
}: ResponsiveDialog) => {
  const showDrawer = useResponsiveDialog();
  const ResponsiveDialogClose = showDrawer ? Drawer.Close : Dialog.Close;

  return (
    <ResponsiveDialogClose className={className} {...props}>
      {children}
    </ResponsiveDialogClose>
  );
};

const ResponsiveDialogContent = ({
  className,
  children,
  ...props
}: ResponsiveDialog) => {
  const showDrawer = useResponsiveDialog();
  const ResponsiveDialogContent = showDrawer ? Drawer.Content : Dialog.Content;

  return (
    <ResponsiveDialogContent className={className} {...props}>
      {children}
    </ResponsiveDialogContent>
  );
};

const ResponsiveDialogDescription = ({
  className,
  children,
  ...props
}: ResponsiveDialog) => {
  const showDrawer = useResponsiveDialog();
  const ResponsiveDialogDescription = showDrawer
    ? Drawer.Description
    : Dialog.Description;

  return (
    <ResponsiveDialogDescription
      {...props}
      className={cn('px-4 md:px-0', className)}
    >
      {children}
    </ResponsiveDialogDescription>
  );
};

const ResponsiveDialogHeader = ({
  className,
  children,
  ...props
}: ResponsiveDialog) => {
  const showDrawer = useResponsiveDialog();
  const ResponsiveDialogHeader = showDrawer ? Drawer.Header : Dialog.Header;

  return (
    <ResponsiveDialogHeader {...props} className={className}>
      {children}
    </ResponsiveDialogHeader>
  );
};

const ResponsiveDialogTitle = ({
  className,
  children,
  ...props
}: ResponsiveDialog) => {
  const showDrawer = useResponsiveDialog();
  const ResponsiveDialogTitle = showDrawer ? Drawer.Title : Dialog.Title;

  return (
    <ResponsiveDialogTitle {...props} className={className}>
      {children}
    </ResponsiveDialogTitle>
  );
};

const ResponsiveDialogBody = ({ className, children }: ResponsiveDialog) => {
  return <div className={cn('px-4 md:px-0', className)}>{children}</div>;
};

const ResponsiveDialogFooter = ({
  className,
  children,
  ...props
}: ResponsiveDialog) => {
  const showDrawer = useResponsiveDialog();
  const ResponsiveDialogFooter = showDrawer ? Drawer.Footer : Dialog.Footer;

  return (
    <ResponsiveDialogFooter {...props} className={className}>
      {children}
    </ResponsiveDialogFooter>
  );
};

const ResponsiveDialog = Object.assign(ResponsiveDialogRoot, {
  Trigger: ResponsiveDialogTrigger,
  Close: ResponsiveDialogClose,
  Content: ResponsiveDialogContent,
  Header: ResponsiveDialogHeader,
  Title: ResponsiveDialogTitle,
  Description: ResponsiveDialogDescription,
  Body: ResponsiveDialogBody,
  Footer: ResponsiveDialogFooter,
});

export default ResponsiveDialog;
