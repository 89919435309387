import store from '@src/store/index';
import {
  hideMaintenancePage,
  showMaintenancePage,
} from '@src/store/slices/global';
import axios from 'axios';
import { maintenancePollingInterval } from 'utils/constants';

export const baseUrl = import.meta.env.VITE_APP_BASE_URL;

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
  withCredentials: true,
  headers: { Accept: 'application/json' },
  validateStatus: function (status) {
    return (
      (status >= 200 && status < 300) ||
      status === 401 ||
      status === 422 ||
      status === 403
    );
  },
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === 503) {
      // Handle maintenance mode
      await handleMaintenanceMode();
      // Retry the original request
      return api.request(error.config);
    }

    return Promise.reject(error);
  }
);

async function handleMaintenanceMode() {
  store.dispatch(showMaintenancePage());

  while (true) {
    await new Promise((resolve) =>
      setTimeout(resolve, maintenancePollingInterval)
    );
    try {
      const response = await fetch(`${baseUrl}/up`);
      if (response.status === 200) {
        store.dispatch(hideMaintenancePage());
        return true;
      }
    } catch (error) {
      // keep polling
    }
  }
}

export function setUserLoggedIn() {
  localStorage.setItem('userLoggedIn', true);
  localStorage.removeItem('token');
  document.cookie =
    'atarim_guest_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie =
    'guest_user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

export function clearLocalStorage() {
  // const { localStorage } = window;
  for (var k in window.localStorage) {
    if (
      k === 'gsc' ||
      k === 'gscs' ||
      k === 'gscw' ||
      k === 'atarim_redirect' ||
      (k === 'token') | (k === 'gist-state') ||
      k === 'showWidget' ||
      k === 'ChatDetails' ||
      k == 'godaddy_invite_token'
    )
      continue;
    delete window.localStorage[k];
  }
}

function actualGet(url, paramObj = {}) {
  return instance
    .get(url, {
      params: paramObj,
    })
    .then((response) => {
      if (response.status === 200) {
        return { success: true, data: response.data };
      } else if (response.status === 401) {
        return { success: false, unauthenticated: true };
      } else if (response.status === 422) {
        return { success: false, message: response?.data?.message };
      } else if (response.status === 403) {
        return { success: false, message: response?.data?.identifier };
      }
      return { success: false, message: 'Something went wrong!' };
    })
    .catch((error) => {
      return { success: false, message: error.message };
    });
}

async function actualPost(url, paramObj, headers = {}) {
  return instance
    .post(url, paramObj, {
      headers,
    })
    .then((response) => {
      switch (response.status) {
        case 200:
          return { success: true, data: response.data };
        case 201:
          return { success: true, data: response.data };
        case 401:
          return { success: false, unauthenticated: true };
        case 403:
          return { success: false, message: response.data?.message };
        case 422:
          const errors = response.data.errors;
          return {
            success: false,
            message: errors
              ? Object.keys(errors).map((k) => errors[k])
              : response?.data?.message,
          };
        default:
      }
      return { success: false, message: 'Something went wrong!' };
    })
    .catch((error) => {
      return { success: false, message: error.message };
    });
}

function actualDelete(url, paramObj = {}, headers = {}) {
  return instance
    .delete(url, {
      params: paramObj,
      headers,
    })
    .then((response) => {
      if (response.status === 200) {
        return { success: true, data: response.data };
      } else if (response.status === 204) {
        return {
          success: true,
          data: response.data ? response.data : { status: true },
        };
      } else if (response.status === 401) {
        return { success: false, unauthenticated: true };
      }
      return { success: false, message: 'Something went wrong!' };
    })
    .catch((error) => {
      return { success: false, message: error.message };
    });
}

function actualPut(url, paramObj, headers = {}) {
  if (headers.socket_id) {
    instance.defaults.headers.common['X-Socket-ID'] = headers.socket_id;
  }
  return instance
    .put(url, paramObj)
    .then((response) => {
      switch (response.status) {
        case 200:
          return { success: true, data: response.data };
        case 401:
          return { success: false, unauthenticated: true };
        case 403:
          return { success: false, message: response.data?.message };
        case 422:
          const errors = response.data.errors;
          return {
            success: false,
            message: errors
              ? Object.keys(errors).map((k) => errors[k])
              : response?.data?.message,
          };
        default:
      }
      return { success: false, message: 'Something went wrong!' };
    })
    .catch((error) => {
      return { success: false, message: error.message };
    });
}

function get(url, paramObj = {}, header = {}) {
  return actualGet(url, paramObj, header)
    .then((response) => {
      if (response.success) {
        return response.data;
      } else if (response.unauthenticated) {
        return { status: false, unauthenticated: true };
      }
      return {
        status: false,
        message: response.message || 'Ill formed response!',
      };
    })
    .catch((error) => {
      return {
        status: false,
        message: error.message || 'Something went wrong! Try again later',
      };
    });
}

//headers is used if you want to add request specific header like X-Socket_ID, so you can add socket_id key in headers object and the header shall be added in request
function post(url, paramObj, headers = {}) {
  return actualPost(url, paramObj, headers)
    .then((response) => {
      if (response.success) {
        return response.data;
      } else if (response.unauthenticated) {
        return { status: false, unauthenticated: true };
      }
      return {
        status: false,
        message: response.message || 'Ill formed response!',
      };
    })
    .catch((error) => {
      return {
        status: false,
        message: error.message || 'Something went wrong! Try again later',
      };
    });
}

function trash(url, paramObj = {}, header = {}) {
  return actualDelete(url, paramObj, header)
    .then((response) => {
      if (response.success) {
        return response.data;
      } else if (response.unauthenticated) {
        return { status: false, unauthenticated: true };
      }
      return {
        status: false,
        message: response.message || 'Ill formed response!',
      };
    })
    .catch((error) => {
      return {
        status: false,
        message: error.message || 'Something went wrong! Try again later',
      };
    });
}

function put(url, paramObj, headers = {}) {
  return actualPut(url, paramObj, headers)
    .then((response) => {
      if (response.success) {
        return response.data;
      } else if (response.unauthenticated) {
        return { status: false, unauthenticated: true };
      }
      return {
        status: false,
        message: response.message || 'Ill formed response!',
      };
    })
    .catch((error) => {
      return {
        status: false,
        message: error.message || 'Something went wrong! Try again later',
      };
    });
}

export default {
  get,
  post,
  trash,
  put,
  actualDelete,
  clearLocalStorage,
  setUserLoggedIn,
};
