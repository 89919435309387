import { FIXME } from '@src/types';
import { GlobalSettings } from '../../types/setting';
import { baseApi } from '../baseApi';

type UpdateGlobalSettingsParams = {
  workspace_id: number;
} & Partial<{
  from_name: string;
  email_signature: string;
  inbox_email_address: string;
  wpf_every_new_comment: number;
  wpf_every_new_complete: number;
  wpf_every_status_change: number;
  wpf_every_new_task: number;
}>;

type NotificationPreference = {
  label: string;
  value: string;
  selected: boolean;
};

type Contributor = {
  id: string;
  normal_id: number;
  name: string;
  first_name: string;
  last_name: string | null;
  email: string;
  avatar: string | null;
  role: string;
  notification_preference: NotificationPreference[];
  add_to_website_default: number;
  assign_to_task_default: boolean;
  registration_user: number;
  checked: boolean;
  selected: boolean;
  two_factor_auth_verified: boolean;
  assigned: boolean;
};

type FetchContributorsParams = {
  project_id?: string;
  page_no: number;
  limit?: number;
  workspace_id: number;
};

type FetchProjectContributorsParams = {
  site_id?: string;
  workspace_id: number;
};

type FetchContributorsResponse = {
  status: boolean;
  data: Contributor[];
  more_web_records: boolean;
};

export type AddSiteRequest = {
  site_id: string;
  attach_contributor: boolean;
  user_id: string;
};

export type AddSiteResponse = {
  // Define the response type based on your API
  success: boolean;
  data: {
    // Add specific fields returned by the API
    id: string;
    // ... other fields
  };
};

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // Existing Queries
    fetchGlobalSettings: build.query<GlobalSettings, number>({
      query: (workspace_id) =>
        `/user/global-settings?workspace_id=${workspace_id}`,
      transformResponse: (response: { data: GlobalSettings }) => response.data,
      providesTags: (_, error, workspace_id) => {
        if (error) return [];
        return [{ type: 'Setting', id: workspace_id }];
      },
    }),
    // New Query
    fetchContributors: build.query<
      FetchContributorsResponse,
      FetchContributorsParams
    >({
      query: ({ project_id, page_no, limit = 20, workspace_id }) => ({
        url: '/user/contributors',
        params: { project_id, page_no, limit, workspace_id },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Contributor' as const,
                id,
              })),
              { type: 'Contributor', id: 'LIST' },
            ]
          : [{ type: 'Contributor', id: 'LIST' }],
    }),

    fetchProjectContributors: build.query<
      FetchContributorsResponse,
      FetchProjectContributorsParams
    >({
      query: ({ workspace_id, site_id }) => ({
        url: `/site/contributors/${site_id}`,
        params: { workspace_id },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Contributor' as const,
                id,
              })),
              { type: 'Contributor', id: 'LIST' },
            ]
          : [{ type: 'Contributor', id: 'LIST' }],
    }),
    // Existing Mutations
    updateGlobalSettings: build.mutation<FIXME, UpdateGlobalSettingsParams>({
      query: (body) => ({
        url: '/user/update-global-settings',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, error, { workspace_id }) => {
        if (error) return [];
        return [{ type: 'Setting', id: workspace_id }];
      },
    }),

    removeCollaborator: build.mutation<AddSiteResponse, AddSiteRequest>({
      query: (body) => ({
        url: '/user/add-site',
        method: 'POST',
        body,
      }),
      // Invalidate the Project list cache when a new site is added
      invalidatesTags: [
        { type: 'Project', id: 'LIST' },
        { type: 'Site', id: 'LIST' },
      ],
    }),

    verifyDomain: build.mutation<any, { email: string; workspace_id: number }>({
      query: ({ email, workspace_id }) => ({
        url: `/user/verify-domain?workspace_id=${workspace_id}`,
        method: 'POST',
        body: { email },
      }),
      invalidatesTags: (_, error, { workspace_id }) => {
        if (error) return [];
        return [{ type: 'Setting', id: workspace_id }];
      },
    }),
    updateUserDetail: build.mutation<
      any,
      { name: string; new_password: string }
    >({
      query: (params) => ({
        url: `/api-change-password`,
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

export const {
  // Queries
  useFetchGlobalSettingsQuery,
  useFetchContributorsQuery,
  useFetchProjectContributorsQuery,
  // Mutations
  useUpdateGlobalSettingsMutation,
  useRemoveCollaboratorMutation,
  useVerifyDomainMutation,
  useUpdateUserDetailMutation,
} = userApi;
