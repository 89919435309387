import React from 'react';

const FrillWidget = () => {
  return (
    <embed
      src="https://app.frill.co/embed/widget/f4864bfc-d706-45b2-9e9d-736b6194d71b"
      style={{
        height: '100vh',
        width: 384,
      }}
    ></embed>
  );
};

export default FrillWidget;
