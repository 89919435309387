import LockFeatureModal from '@src/components/tasks/LockFeaturePopup';
import CreateWorkspaceModal from '@src/components/workspace/CreateWorkspace';
import { Lock, LogOut, Plus, Settings } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Avatar, Separator } from 'ui';
import Button from 'ui/components/Button';

import { useAuth } from '@src/hooks/useAuth';
import { Drawer } from 'ui/components';
import { cn } from 'utils/cn';
import { formatNumber } from 'utils/functions';
import { AdminRoles } from '../../../../../utils/constants';

interface Workspace {
  id: string;
  name: string;
  icon?: string;
  email?: string;
  is_locked: boolean;
  user_id: string;
  project_count: number;
  role: string;
  pivot: {
    is_default: boolean;
  };
}

interface UserState {
  userData: {
    normal_id: string;
    source?: string;
  };
  isLoading: boolean;
}

interface WorkspaceState {
  workspaceData: Workspace[];
  selectedWorkspace: Workspace | null;
}

interface RootState {
  user: UserState;
  workspace: WorkspaceState;
}

interface SortedWorkspaces {
  personalWorkspaces: Workspace[];
  externalWorkspaces: Workspace[];
}

const WorkspaceDropdown: React.FC = () => {
  const { workspaceData, selectedWorkspace } = useSelector(
    (state: RootState) => state.workspace
  );
  const { logoutUser } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userData, isLoading } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [openWorkplace, setOpenWorkplace] = useState<boolean | null>(null);
  const [openLockFeatureModal, setOpenLockFeatureModal] =
    useState<boolean>(false);
  const [sortedWorkspaces, setSortedWorkspaces] =
    useState<SortedWorkspaces | null>(null);
  const [open, setOpen] = useState(false);
  const closeDrawer = () => setOpen(false);
  const openCreateWorkplace = () => {
    setOpenWorkplace(!openWorkplace);
  };

  const sortWorkspaces = (workspaces: Workspace[]) => {
    const personalWorkspaces: Workspace[] = [];
    const externalWorkspaces: Workspace[] = [];

    workspaces.forEach((ws) => {
      (ws.user_id === userData.normal_id
        ? personalWorkspaces
        : externalWorkspaces
      ).push(ws);
    });

    setSortedWorkspaces({ personalWorkspaces, externalWorkspaces });
  };

  const selectWorkspaceAndNavigate = (value: string) => {
    if (isLoading) {
      return;
    }
    const workspace = workspaceData.find((workspace) => workspace.id === value);
    if (!workspace) return;

    localStorage.setItem('selectedWorkspace', JSON.stringify(workspace));
    dispatch({ type: 'SET_SELECTED_WORKSPACE', workspace });
    dispatch({
      type: 'STORE_SELECTED_WORKSPACE',
      workspaceId: { workspace_id: value },
    });
    setSearchParams({});

    if (location.pathname.includes('collaborate')) {
      navigate(`/projects`);
    }
    if (isUserAdmin() && location.pathname.includes('settings')) {
      navigate(`/projects`);
    }
  };

  const isUserAdmin = () => {
    return AdminRoles.indexOf(selectedWorkspace?.role ?? '') !== -1;
  };

  useEffect(() => {
    if (workspaceData.length > 0) {
      sortWorkspaces(workspaceData);
    }
  }, [workspaceData, userData]);

  return (
    <Drawer shouldScaleBackground={true} open={open} onOpenChange={setOpen}>
      <Drawer.Trigger asChild className="mx-auto flex self-center">
        <Button className="flex h-full flex-col items-center justify-center gap-0.5 border-none bg-transparent px-0 py-1.5 text-[10px] font-normal text-secondary">
          <Avatar
            src={selectedWorkspace?.icon}
            alt={selectedWorkspace?.name}
            id={selectedWorkspace?.id}
            size="fit"
            className="size-[18px] text-[10px]"
            title={selectedWorkspace?.name}
          />
          Workspaces
        </Button>
      </Drawer.Trigger>

      <Drawer.Content className="px-4 pb-4">
        <Drawer.Header className="pl-0">
          <Drawer.Title className="text-left">Workspaces</Drawer.Title>
        </Drawer.Header>
        <div className="max-h-[75vh] overflow-y-auto">
          {sortedWorkspaces &&
            sortedWorkspaces.personalWorkspaces.length > 0 && (
              <div className="flex flex-col gap-2">
                <>
                  {sortedWorkspaces?.personalWorkspaces.map(
                    (workspace, index) => (
                      <div
                        key={index}
                        //  disabled={workspace.is_locked}
                        onClick={() =>
                          (!workspace.is_locked &&
                            selectWorkspaceAndNavigate(workspace.id)) ||
                          closeDrawer()
                        }
                        className={cn(
                          workspace.is_locked
                            ? 'touch-none select-none opacity-50'
                            : ''
                        )}
                      >
                        <span className="flex flex-row items-center justify-between">
                          <div className="flex flex-row items-center gap-2 overflow-auto">
                            <span className="relative p-1">
                              <Avatar
                                src={workspace.icon}
                                alt={workspace.name}
                                id={workspace.id}
                                size="lg"
                                borderRadius="lg"
                                title={workspace.name}
                              />
                              <span className="absolute -right-1 -top-0 z-10 h-5 min-w-5 rounded-[20px] border-[3px] border-white bg-[#E72D67] px-1 text-center text-xs leading-tight text-white">
                                {workspace.is_locked ? (
                                  <Lock width={10} height={10} />
                                ) : (
                                  formatNumber(workspace.project_count)
                                )}
                              </span>
                            </span>
                            <span className="max-w-[250px] truncate font-medium">
                              {workspace.name}
                            </span>
                          </div>
                          {workspace.id === selectedWorkspace?.id &&
                            isUserAdmin() && (
                              <Button
                                size="icon"
                                variant="ghost"
                                leftIcon={<Settings size={18} />}
                                onClick={() => {
                                  !workspace.is_locked &&
                                    navigate(
                                      `/settings?tab_flag=workspace&edit=${true}`
                                    );
                                  dispatch({
                                    type: 'SET_SELECTED_WORKSPACE_SETTINGS',
                                    workspace,
                                    id: workspace?.id,
                                  });
                                  closeDrawer();
                                }}
                              ></Button>
                            )}
                        </span>
                      </div>
                    )
                  )}
                </>
              </div>
            )}
          {sortedWorkspaces &&
            sortedWorkspaces.externalWorkspaces.length > 0 && (
              <>
                <div className="my-2 flex w-full items-center gap-1 text-xs font-semibold text-gray-300">
                  <span>External</span>
                  <Separator className="shrink bg-gray-200" />
                </div>
                {sortedWorkspaces.externalWorkspaces.map((workspace, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      !workspace.is_locked &&
                      selectWorkspaceAndNavigate(workspace.id)
                    }
                    className={cn(
                      workspace.is_locked
                        ? 'touch-none select-none opacity-50'
                        : ''
                    )}
                  >
                    <div className="flex flex-row items-center gap-2 overflow-auto">
                      <span className="relative p-1">
                        <Avatar
                          src={workspace.icon}
                          alt={workspace.name}
                          id={workspace.id}
                          size="lg"
                          borderRadius="lg"
                          title={workspace.name}
                        />
                        <span className="absolute -right-1 -top-0 z-10 h-5 min-w-5 rounded-[20px] border-[3px] border-white bg-[#E72D67] px-1 text-center text-xs leading-tight text-white">
                          {workspace.is_locked ? (
                            <Lock width={10} height={10} />
                          ) : (
                            formatNumber(workspace.project_count)
                          )}
                        </span>
                      </span>
                      <span className="max-w-[250px] truncate font-medium">
                        {workspace.name}
                      </span>
                    </div>
                  </div>
                ))}
              </>
            )}
        </div>
        {isUserAdmin() && (
          <Button
            className="mt-3 w-full"
            size="sm"
            onClick={() => openCreateWorkplace()}
            leftIcon={<Plus size={18} />}
          >
            Create A New Workspace
          </Button>
        )}
        <Button
          className="mt-2 w-full"
          size="sm"
          variant="secondary"
          leftIcon={<LogOut size={18} />}
          onClick={() => {
            logoutUser();
          }}
        >
          Log Out
        </Button>

        {/* <Drawer.Close asChild>
          <Button size="sm" variant="secondary" className="mt-2">
            Close
          </Button>
        </Drawer.Close> */}
      </Drawer.Content>
    </Drawer>
  );

  {
    openWorkplace && (
      <CreateWorkspaceModal
        open={openWorkplace}
        openCreateWorkplace={openCreateWorkplace}
        title={`Create a new Workspace`}
        setOpenLockFeatureModal={setOpenLockFeatureModal}
      />
    );
  }
  <LockFeatureModal
    openLockFeatureModal={openLockFeatureModal}
    lockTitle={'Workspaces'}
    lockParagraph={
      'Create a new workspace with a trial of our Starter plan or upgrade this workspace to unlock more slots.'
    }
    lockImg={
      'https://wpfeedback-image.s3.us-east-2.amazonaws.com/media/locked-workspaces.png'
    }
    handleCloseLock={() => setOpenLockFeatureModal(false)}
    upSellUrl={`${import.meta.env.VITE_APP_MAIN_WEBSITE_URL}/upgrade${userData.source ? `?_from=${userData.source}` : ''
      }`}
  />;
};

export default WorkspaceDropdown;
