import { RootState } from '@src/store';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, ToggleGroup, ToggleGroupItem } from 'ui';
import { cn } from 'utils/cn';
import { getMenuList } from './MobileMenuList';
import MobileNotifications from './MobileNotifications';
import WorkspaceButton from './WorkspaceButton';

export default function MobileNav() {
  const pathname = window.location.pathname;
  const menuList = getMenuList(pathname);
  const user = useSelector((state: RootState) => state.user.userData);

  return (
    <div className="w-100 flex justify-between gap-1 bg-neutral-50 p-2 text-[10px] text-secondary shadow-md">
      <ToggleGroup
        type="multiple"
        className={cn(
          user.role !== 'collaborator' ? 'grid-cols-5' : 'grid-cols-4',
          'grid min-w-full'
        )}
      >
        <ToggleGroupItem
          asChild
          value="option-0"
          className="flex grow items-center justify-center border-none bg-transparent"
        >
          <WorkspaceButton />
        </ToggleGroupItem>
        {menuList.map(({ menus }) =>
          menus.map(({ href, label, icon: Icon, active, submenus }, index) => {
            if (submenus.length === 0) {
              return (
                <ToggleGroupItem
                  asChild
                  value={`option-${index}`}
                  key={index}
                  className="h-12 w-full border-none bg-transparent text-[10px] text-secondary"
                >
                  <Button
                    asChild
                    variant="ghost"
                    className="flex h-full w-full items-center justify-center data-[state=on]:bg-tertiary"
                  >
                    <Link
                      to={href}
                      className={cn(
                        active ? 'text-white bg-tertiary' : '',
                        'flex h-full w-full flex-col items-center gap-0.5 rounded-md p-1.5'
                      )}
                    >
                      <Icon size={18} />
                      {label}
                    </Link>
                  </Button>
                </ToggleGroupItem>
              );
            }
            return null;
          })
        )}
        {user.role !== 'collaborator' && (
          <ToggleGroupItem
            asChild
            value="option-4"
            className="flex items-center justify-center border-none bg-transparent"
          >
            <MobileNotifications />
          </ToggleGroupItem>
        )}
      </ToggleGroup>
    </div>
  );
}
