import FeatureFlagWrapper from '@src/components/FeatureFlagWrapper';
import MobileNav from '@src/components/navs/MobileNav/MobileNav';
import AppSidebar from '@src/components/navs/SideNavigation';
import SiteNav from '@src/components/navs/SiteNav';
import { useAppDispatch, useAppSelector } from '@src/hooks/redux';
import { useModal } from '@src/providers/modal-provider';
import {
  bulkUpdateFeatureFlags,
  FeatureFlags,
} from '@src/store/slices/featureflags';
import { SETTINGS_PAGE_MOUNTED } from 'actions/global_settings';
import { usePostHog } from 'posthog-js/react';
import { Suspense, useEffect } from 'react';
import { NotificationContainer } from 'react-notifications';
import { ProductFruits } from 'react-product-fruits';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  sendMessageToExtension,
  sendMessageToSiteControl,
} from 'utils/chromeExtension';
import { useResponsive } from 'utils/useMediaQuery';
import PageLoading from '../components/PageLoading';
import PlanUpgrading from '../components/plans/PlansUpgrading';
import usePluginActivationRedirect from '../hooks/pluginRedirect';
import useUpgradePlanEffect from '../hooks/use-open-upgrade';
import { useAuth } from '../hooks/useAuth';
import useExport from '../hooks/useExport';
import useWorkspaceChangeEffect from '../hooks/workspaceChange';
import ErrorBoundary from './ErrorBoundary';


export default function Layout() {
  const { userData, token, isPlansDialogOpened, userTrialData } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { isLoadingAuth } = useAuth();
  const location = useLocation();
  const { isMobile, isDesktop, isTablet, isLargeDesktop } = useResponsive();
  const { workspaceData } = useAppSelector((state) => state.workspace);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const posthogClient = usePostHog();
  // Feature flags
  dispatch(
    bulkUpdateFeatureFlags({
      [FeatureFlags.COLLABORATE_V2]: posthogClient.isFeatureEnabled(
        FeatureFlags.COLLABORATE_V2
      ),
    })
  );

  usePluginActivationRedirect();
  useWorkspaceChangeEffect();
  //TODO: Move this to inbox and board, once board is a functional component
  useExport();

  // TODO: this may be moved to a separate hook or removed
  useEffect(() => {
    if (!isLoadingAuth) {
      if (token && localStorage.getItem('token') !== 'guestToken') {
        dispatch({ type: SETTINGS_PAGE_MOUNTED });
      } else if (!token) {
        sendMessageToExtension({ type: 'logout' });
      }
    }
  }, [token, isLoadingAuth]);
  useEffect(() => {
    if (
      userData &&
      userData.trial?.status === 'inactive' &&
      !userData.trial?.company_name
    ) {
      if (location.pathname === '/collaborate') return;
      return navigate(`/get-started${location.search}`);
    }
  }, [userData]);

  // TODO: Clean up this effect into hooks
  useEffect(() => {
    if (userData) {
      window.gsc('params', {
        plan: !userData?.plan?.user_permissions && 'false',
      });

      posthogClient.identify(userData.id + '_' + userData.email, {
        email: userData.email,
        plan: userData?.plan?.title,
        username: userData.username,
        environment: import.meta.env.VITE_APP_BASE_URL,
      });
    } else {
      posthogClient.reset();
    }
    sendMessageToSiteControl();
  }, [userData, workspaceData, dispatch, posthogClient]);


  const checkUpgrade = searchParams.get("upgrade");

  useEffect(() => {

    if (checkUpgrade) {
      dispatch({ type: "HANDLE_PLANS_DIALOG", params: true });
    }
  }, [checkUpgrade])

  const startWizard = useModal('start_wizard');
  useEffect(() => {
    if (isPlansDialogOpened) {
      startWizard.forceClose();
    } else if (
      userData?.role === 'admin' &&
      userTrialData?.status === 'inactive' && location.pathname !== '/get-started'
    ) {
      startWizard.open({
        onClose: (value) => {
          if (value) return;
          dispatch({ type: "CLOSE_TRIAL_WIZARD" });
        },
      });
    }
  }, [userData, isPlansDialogOpened]);
  useUpgradePlanEffect()

  return (
    <ErrorBoundary>
      <NotificationContainer />
      {userData ? (
        <>
          {location.pathname !== '/fetching' &&
            location.pathname !== '/get-started' &&
            (isTablet || isDesktop || isLargeDesktop) &&
            <FeatureFlagWrapper
              flagName="Sidebar-V2"
              featureOn={<AppSidebar />}
              featureOff={<SiteNav />}
            />
          }
          <div className="flex h-screen w-full flex-col overflow-y-auto">
            {/* REMOVE TRAIL HEADER WHEN SIDEBAR TRIAL WIDGET IS COMPLETE */}
            {/* {location.pathname !== '/collaborate' && <TrialHeaderBar />} */}
            <Suspense fallback={<PageLoading />}>
              <Outlet />
              {userData && isPlansDialogOpened && (
                <PlanUpgrading title={userData.subscription ? 'Renew Subscription' : 'Upgrade Plan'} />
              )}
              <ProductFruits
                lifeCycle='neverUnmount'
                config={{
                  customNavigation: {
                    use: true,
                    navigate: (url: string) => {
                      if (url.startsWith('https')) {
                        window.location.href = url;
                      } else {
                        navigate(url);
                      }
                    },
                    onGet() {
                      return window.location.href;
                    },
                  },
                }}
                workspaceCode="02hLgOXsohviuqyd" language="en" user={{
                  username: userData.email,
                  email: userData.email,
                  firstname: userData.first_name,
                  lastname: userData.last_name,
                  role: userData.role,
                  props: { plan: userData.plan },
                }}
              />
            </Suspense>
            {isMobile && <MobileNav />}
          </div>
        </>
      ) : (
        <div className="content">
          <Suspense fallback={<PageLoading />}>
            <Outlet />
          </Suspense>
        </div>
      )}
    </ErrorBoundary>
  );
}

