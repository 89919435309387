import V from "i18next";
import { initReactI18next as ye, useTranslation as ve } from "react-i18next";
import { useCallback as we } from "react";
function me(s, o) {
  for (var t = 0; t < o.length; t++) {
    const e = o[t];
    if (typeof e != "string" && !Array.isArray(e)) {
      for (const n in e)
        if (n !== "default" && !(n in s)) {
          const i = Object.getOwnPropertyDescriptor(e, n);
          i && Object.defineProperty(s, n, i.get ? i : {
            enumerable: !0,
            get: () => e[n]
          });
        }
    }
  }
  return Object.freeze(Object.defineProperty(s, Symbol.toStringTag, { value: "Module" }));
}
var ne = [], be = ne.forEach, Ee = ne.slice;
function T(s) {
  return be.call(Ee.call(arguments, 1), function(o) {
    if (o)
      for (var t in o)
        s[t] === void 0 && (s[t] = o[t]);
  }), s;
}
function je(s, o, t) {
  var e;
  return function() {
    var n = this, i = arguments, a = function() {
      e = null, s.apply(n, i);
    };
    clearTimeout(e), e = setTimeout(a, o);
  };
}
function K(s, o, t) {
  function e(a) {
    return a && a.indexOf("###") > -1 ? a.replace(/###/g, ".") : a;
  }
  for (var n = typeof o != "string" ? [].concat(o) : o.split("."); n.length > 1; ) {
    if (!s) return {};
    var i = e(n.shift());
    !s[i] && t && (s[i] = new t()), s = s[i];
  }
  return s ? {
    obj: s,
    k: e(n.shift())
  } : {};
}
function H(s, o, t) {
  var e = K(s, o, Object), n = e.obj, i = e.k;
  n[i] = t;
}
function Oe(s, o, t, e) {
  var n = K(s, o, Object), i = n.obj, a = n.k;
  i[a] = i[a] || [], i[a].push(t);
}
function $(s, o) {
  var t = K(s, o), e = t.obj, n = t.k;
  if (e)
    return e[n];
}
var Q = new RegExp("{{(.+?)}}", "g");
function Pe(s) {
  return s == null ? "" : "" + s;
}
function I(s, o, t) {
  var e, n;
  function i(a) {
    return a.replace(/\$/g, "$$$$");
  }
  for (; e = Q.exec(s); )
    n = e[1].trim(), typeof n != "string" && (n = Pe(n)), n || (n = ""), n = i(n), s = s.replace(e[0], o[n] || n), Q.lastIndex = 0;
  return s;
}
function q(s, o) {
  return o.reduce(function(t, e) {
    if (t) return t;
    if (!s || !s[e] || typeof s[e] != "string" || !s[e].toLowerCase() === e.toLowerCase()) {
      var n = 'i18next-locize-backend :: got "'.concat(s[e], '" in options for ').concat(e, " which is invalid.");
      return console.warn(n), n;
    }
    return !1;
  }, !1);
}
function Y() {
  var s, o, t = new Promise(function(e, n) {
    s = e, o = n;
  });
  return t.resolve = s, t.reject = o, t;
}
var S = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function xe(s) {
  return s && s.__esModule && Object.prototype.hasOwnProperty.call(s, "default") ? s.default : s;
}
function Le(s) {
  throw new Error('Could not dynamically require "' + s + '". Please configure the dynamicRequireTargets or/and ignoreDynamicRequires option of @rollup/plugin-commonjs appropriately for this require call to work.');
}
var X = { exports: {} }, B = { exports: {} }, Z;
function Ae() {
  return Z || (Z = 1, function(s, o) {
    var t = typeof globalThis < "u" && globalThis || typeof self < "u" && self || typeof S < "u" && S, e = function() {
      function i() {
        this.fetch = !1, this.DOMException = t.DOMException;
      }
      return i.prototype = t, new i();
    }();
    (function(i) {
      (function(a) {
        var c = typeof i < "u" && i || typeof self < "u" && self || typeof c < "u" && c, u = {
          searchParams: "URLSearchParams" in c,
          iterable: "Symbol" in c && "iterator" in Symbol,
          blob: "FileReader" in c && "Blob" in c && function() {
            try {
              return new Blob(), !0;
            } catch {
              return !1;
            }
          }(),
          formData: "FormData" in c,
          arrayBuffer: "ArrayBuffer" in c
        };
        function d(r) {
          return r && DataView.prototype.isPrototypeOf(r);
        }
        if (u.arrayBuffer)
          var h = [
            "[object Int8Array]",
            "[object Uint8Array]",
            "[object Uint8ClampedArray]",
            "[object Int16Array]",
            "[object Uint16Array]",
            "[object Int32Array]",
            "[object Uint32Array]",
            "[object Float32Array]",
            "[object Float64Array]"
          ], l = ArrayBuffer.isView || function(r) {
            return r && h.indexOf(Object.prototype.toString.call(r)) > -1;
          };
        function p(r) {
          if (typeof r != "string" && (r = String(r)), /[^a-z0-9\-#$%&'*+.^_`|~!]/i.test(r) || r === "")
            throw new TypeError('Invalid character in header field name: "' + r + '"');
          return r.toLowerCase();
        }
        function w(r) {
          return typeof r != "string" && (r = String(r)), r;
        }
        function j(r) {
          var f = {
            next: function() {
              var g = r.shift();
              return { done: g === void 0, value: g };
            }
          };
          return u.iterable && (f[Symbol.iterator] = function() {
            return f;
          }), f;
        }
        function y(r) {
          this.map = {}, r instanceof y ? r.forEach(function(f, g) {
            this.append(g, f);
          }, this) : Array.isArray(r) ? r.forEach(function(f) {
            this.append(f[0], f[1]);
          }, this) : r && Object.getOwnPropertyNames(r).forEach(function(f) {
            this.append(f, r[f]);
          }, this);
        }
        y.prototype.append = function(r, f) {
          r = p(r), f = w(f);
          var g = this.map[r];
          this.map[r] = g ? g + ", " + f : f;
        }, y.prototype.delete = function(r) {
          delete this.map[p(r)];
        }, y.prototype.get = function(r) {
          return r = p(r), this.has(r) ? this.map[r] : null;
        }, y.prototype.has = function(r) {
          return this.map.hasOwnProperty(p(r));
        }, y.prototype.set = function(r, f) {
          this.map[p(r)] = w(f);
        }, y.prototype.forEach = function(r, f) {
          for (var g in this.map)
            this.map.hasOwnProperty(g) && r.call(f, this.map[g], g, this);
        }, y.prototype.keys = function() {
          var r = [];
          return this.forEach(function(f, g) {
            r.push(g);
          }), j(r);
        }, y.prototype.values = function() {
          var r = [];
          return this.forEach(function(f) {
            r.push(f);
          }), j(r);
        }, y.prototype.entries = function() {
          var r = [];
          return this.forEach(function(f, g) {
            r.push([g, f]);
          }), j(r);
        }, u.iterable && (y.prototype[Symbol.iterator] = y.prototype.entries);
        function E(r) {
          if (r.bodyUsed)
            return Promise.reject(new TypeError("Already read"));
          r.bodyUsed = !0;
        }
        function x(r) {
          return new Promise(function(f, g) {
            r.onload = function() {
              f(r.result);
            }, r.onerror = function() {
              g(r.error);
            };
          });
        }
        function ae(r) {
          var f = new FileReader(), g = x(f);
          return f.readAsArrayBuffer(r), g;
        }
        function fe(r) {
          var f = new FileReader(), g = x(f);
          return f.readAsText(r), g;
        }
        function ue(r) {
          for (var f = new Uint8Array(r), g = new Array(f.length), m = 0; m < f.length; m++)
            g[m] = String.fromCharCode(f[m]);
          return g.join("");
        }
        function G(r) {
          if (r.slice)
            return r.slice(0);
          var f = new Uint8Array(r.byteLength);
          return f.set(new Uint8Array(r)), f.buffer;
        }
        function J() {
          return this.bodyUsed = !1, this._initBody = function(r) {
            this.bodyUsed = this.bodyUsed, this._bodyInit = r, r ? typeof r == "string" ? this._bodyText = r : u.blob && Blob.prototype.isPrototypeOf(r) ? this._bodyBlob = r : u.formData && FormData.prototype.isPrototypeOf(r) ? this._bodyFormData = r : u.searchParams && URLSearchParams.prototype.isPrototypeOf(r) ? this._bodyText = r.toString() : u.arrayBuffer && u.blob && d(r) ? (this._bodyArrayBuffer = G(r.buffer), this._bodyInit = new Blob([this._bodyArrayBuffer])) : u.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(r) || l(r)) ? this._bodyArrayBuffer = G(r) : this._bodyText = r = Object.prototype.toString.call(r) : this._bodyText = "", this.headers.get("content-type") || (typeof r == "string" ? this.headers.set("content-type", "text/plain;charset=UTF-8") : this._bodyBlob && this._bodyBlob.type ? this.headers.set("content-type", this._bodyBlob.type) : u.searchParams && URLSearchParams.prototype.isPrototypeOf(r) && this.headers.set("content-type", "application/x-www-form-urlencoded;charset=UTF-8"));
          }, u.blob && (this.blob = function() {
            var r = E(this);
            if (r)
              return r;
            if (this._bodyBlob)
              return Promise.resolve(this._bodyBlob);
            if (this._bodyArrayBuffer)
              return Promise.resolve(new Blob([this._bodyArrayBuffer]));
            if (this._bodyFormData)
              throw new Error("could not read FormData body as blob");
            return Promise.resolve(new Blob([this._bodyText]));
          }, this.arrayBuffer = function() {
            if (this._bodyArrayBuffer) {
              var r = E(this);
              return r || (ArrayBuffer.isView(this._bodyArrayBuffer) ? Promise.resolve(
                this._bodyArrayBuffer.buffer.slice(
                  this._bodyArrayBuffer.byteOffset,
                  this._bodyArrayBuffer.byteOffset + this._bodyArrayBuffer.byteLength
                )
              ) : Promise.resolve(this._bodyArrayBuffer));
            } else
              return this.blob().then(ae);
          }), this.text = function() {
            var r = E(this);
            if (r)
              return r;
            if (this._bodyBlob)
              return fe(this._bodyBlob);
            if (this._bodyArrayBuffer)
              return Promise.resolve(ue(this._bodyArrayBuffer));
            if (this._bodyFormData)
              throw new Error("could not read FormData body as text");
            return Promise.resolve(this._bodyText);
          }, u.formData && (this.formData = function() {
            return this.text().then(de);
          }), this.json = function() {
            return this.text().then(JSON.parse);
          }, this;
        }
        var ce = ["DELETE", "GET", "HEAD", "OPTIONS", "POST", "PUT"];
        function le(r) {
          var f = r.toUpperCase();
          return ce.indexOf(f) > -1 ? f : r;
        }
        function A(r, f) {
          if (!(this instanceof A))
            throw new TypeError('Please use the "new" operator, this DOM object constructor cannot be called as a function.');
          f = f || {};
          var g = f.body;
          if (r instanceof A) {
            if (r.bodyUsed)
              throw new TypeError("Already read");
            this.url = r.url, this.credentials = r.credentials, f.headers || (this.headers = new y(r.headers)), this.method = r.method, this.mode = r.mode, this.signal = r.signal, !g && r._bodyInit != null && (g = r._bodyInit, r.bodyUsed = !0);
          } else
            this.url = String(r);
          if (this.credentials = f.credentials || this.credentials || "same-origin", (f.headers || !this.headers) && (this.headers = new y(f.headers)), this.method = le(f.method || this.method || "GET"), this.mode = f.mode || this.mode || null, this.signal = f.signal || this.signal, this.referrer = null, (this.method === "GET" || this.method === "HEAD") && g)
            throw new TypeError("Body not allowed for GET or HEAD requests");
          if (this._initBody(g), (this.method === "GET" || this.method === "HEAD") && (f.cache === "no-store" || f.cache === "no-cache")) {
            var m = /([?&])_=[^&]*/;
            if (m.test(this.url))
              this.url = this.url.replace(m, "$1_=" + (/* @__PURE__ */ new Date()).getTime());
            else {
              var b = /\?/;
              this.url += (b.test(this.url) ? "&" : "?") + "_=" + (/* @__PURE__ */ new Date()).getTime();
            }
          }
        }
        A.prototype.clone = function() {
          return new A(this, { body: this._bodyInit });
        };
        function de(r) {
          var f = new FormData();
          return r.trim().split("&").forEach(function(g) {
            if (g) {
              var m = g.split("="), b = m.shift().replace(/\+/g, " "), v = m.join("=").replace(/\+/g, " ");
              f.append(decodeURIComponent(b), decodeURIComponent(v));
            }
          }), f;
        }
        function he(r) {
          var f = new y(), g = r.replace(/\r?\n[\t ]+/g, " ");
          return g.split("\r").map(function(m) {
            return m.indexOf(`
`) === 0 ? m.substr(1, m.length) : m;
          }).forEach(function(m) {
            var b = m.split(":"), v = b.shift().trim();
            if (v) {
              var N = b.join(":").trim();
              f.append(v, N);
            }
          }), f;
        }
        J.call(A.prototype);
        function P(r, f) {
          if (!(this instanceof P))
            throw new TypeError('Please use the "new" operator, this DOM object constructor cannot be called as a function.');
          f || (f = {}), this.type = "default", this.status = f.status === void 0 ? 200 : f.status, this.ok = this.status >= 200 && this.status < 300, this.statusText = f.statusText === void 0 ? "" : "" + f.statusText, this.headers = new y(f.headers), this.url = f.url || "", this._initBody(r);
        }
        J.call(P.prototype), P.prototype.clone = function() {
          return new P(this._bodyInit, {
            status: this.status,
            statusText: this.statusText,
            headers: new y(this.headers),
            url: this.url
          });
        }, P.error = function() {
          var r = new P(null, { status: 0, statusText: "" });
          return r.type = "error", r;
        };
        var pe = [301, 302, 303, 307, 308];
        P.redirect = function(r, f) {
          if (pe.indexOf(f) === -1)
            throw new RangeError("Invalid status code");
          return new P(null, { status: f, headers: { location: r } });
        }, a.DOMException = c.DOMException;
        try {
          new a.DOMException();
        } catch {
          a.DOMException = function(f, g) {
            this.message = f, this.name = g;
            var m = Error(f);
            this.stack = m.stack;
          }, a.DOMException.prototype = Object.create(Error.prototype), a.DOMException.prototype.constructor = a.DOMException;
        }
        function D(r, f) {
          return new Promise(function(g, m) {
            var b = new A(r, f);
            if (b.signal && b.signal.aborted)
              return m(new a.DOMException("Aborted", "AbortError"));
            var v = new XMLHttpRequest();
            function N() {
              v.abort();
            }
            v.onload = function() {
              var O = {
                status: v.status,
                statusText: v.statusText,
                headers: he(v.getAllResponseHeaders() || "")
              };
              O.url = "responseURL" in v ? v.responseURL : O.headers.get("X-Request-URL");
              var U = "response" in v ? v.response : v.responseText;
              setTimeout(function() {
                g(new P(U, O));
              }, 0);
            }, v.onerror = function() {
              setTimeout(function() {
                m(new TypeError("Network request failed"));
              }, 0);
            }, v.ontimeout = function() {
              setTimeout(function() {
                m(new TypeError("Network request failed"));
              }, 0);
            }, v.onabort = function() {
              setTimeout(function() {
                m(new a.DOMException("Aborted", "AbortError"));
              }, 0);
            };
            function ge(O) {
              try {
                return O === "" && c.location.href ? c.location.href : O;
              } catch {
                return O;
              }
            }
            v.open(b.method, ge(b.url), !0), b.credentials === "include" ? v.withCredentials = !0 : b.credentials === "omit" && (v.withCredentials = !1), "responseType" in v && (u.blob ? v.responseType = "blob" : u.arrayBuffer && b.headers.get("Content-Type") && b.headers.get("Content-Type").indexOf("application/octet-stream") !== -1 && (v.responseType = "arraybuffer")), f && typeof f.headers == "object" && !(f.headers instanceof y) ? Object.getOwnPropertyNames(f.headers).forEach(function(O) {
              v.setRequestHeader(O, w(f.headers[O]));
            }) : b.headers.forEach(function(O, U) {
              v.setRequestHeader(U, O);
            }), b.signal && (b.signal.addEventListener("abort", N), v.onreadystatechange = function() {
              v.readyState === 4 && b.signal.removeEventListener("abort", N);
            }), v.send(typeof b._bodyInit > "u" ? null : b._bodyInit);
          });
        }
        return D.polyfill = !0, c.fetch || (c.fetch = D, c.Headers = y, c.Request = A, c.Response = P), a.Headers = y, a.Request = A, a.Response = P, a.fetch = D, a;
      })({});
    })(e), e.fetch.ponyfill = !0, delete e.fetch.polyfill;
    var n = t.fetch ? t : e;
    o = n.fetch, o.default = n.fetch, o.fetch = n.fetch, o.Headers = n.Headers, o.Request = n.Request, o.Response = n.Response, s.exports = o;
  }(B, B.exports)), B.exports;
}
(function(s, o) {
  var t;
  if (typeof fetch == "function" && (typeof S < "u" && S.fetch ? t = S.fetch : typeof window < "u" && window.fetch ? t = window.fetch : t = fetch), typeof Le < "u" && typeof window > "u") {
    var e = t || Ae();
    e.default && (e = e.default), o.default = e, s.exports = o.default;
  }
})(X, X.exports);
var oe = X.exports;
const ie = /* @__PURE__ */ xe(oe), ee = /* @__PURE__ */ me({
  __proto__: null,
  default: ie
}, [oe]);
function C(s) {
  "@babel/helpers - typeof";
  return C = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(o) {
    return typeof o;
  } : function(o) {
    return o && typeof Symbol == "function" && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, C(s);
}
var L;
typeof fetch == "function" && (typeof global < "u" && global.fetch ? L = global.fetch : typeof window < "u" && window.fetch ? L = window.fetch : L = fetch);
var R;
(typeof XMLHttpRequest == "function" || (typeof XMLHttpRequest > "u" ? "undefined" : C(XMLHttpRequest)) === "object") && (typeof global < "u" && global.XMLHttpRequest ? R = global.XMLHttpRequest : typeof window < "u" && window.XMLHttpRequest && (R = window.XMLHttpRequest));
var k;
typeof ActiveXObject == "function" && (typeof global < "u" && global.ActiveXObject ? k = global.ActiveXObject : typeof window < "u" && window.ActiveXObject && (k = window.ActiveXObject));
!L && ee && !R && !k && (L = ie || ee);
typeof L != "function" && (L = void 0);
var Te = function(o, t, e, n) {
  var i = {};
  typeof window > "u" && typeof global < "u" && typeof global.process < "u" && global.process.versions && global.process.versions.node && (i["User-Agent"] = "i18next-locize-backend (node/".concat(global.process.version, "; ").concat(global.process.platform, " ").concat(global.process.arch, ")")), o.authorize && o.apiKey && (i.Authorization = o.apiKey), (e || o.setContentTypeJSON) && (i["Content-Type"] = "application/json");
  var a = function(u) {
    var d = u.headers && u.headers.get("x-cache") === "Error from cloudfront";
    if (!u.ok) return n(u.statusText || "Error", {
      status: u.status,
      resourceNotExisting: d
    });
    u.text().then(function(h) {
      n(null, {
        status: u.status,
        data: h,
        resourceNotExisting: d
      });
    }).catch(n);
  };
  typeof fetch == "function" ? fetch(t, {
    method: e ? "POST" : "GET",
    body: e ? JSON.stringify(e) : void 0,
    headers: i
  }).then(a).catch(n) : L(t, {
    method: e ? "POST" : "GET",
    body: e ? JSON.stringify(e) : void 0,
    headers: i
  }).then(a).catch(n);
}, _e = function(o, t, e, n) {
  try {
    var i;
    R ? i = new R() : i = new k("MSXML2.XMLHTTP.3.0"), i.open(e ? "POST" : "GET", t, 1), o.crossDomain || i.setRequestHeader("X-Requested-With", "XMLHttpRequest"), o.authorize && o.apiKey && i.setRequestHeader("Authorization", o.apiKey), (e || o.setContentTypeJSON) && i.setRequestHeader("Content-Type", "application/json"), i.onreadystatechange = function() {
      var a = i.getResponseHeader("x-cache") === "Error from cloudfront";
      i.readyState > 3 && n(i.status >= 400 ? i.statusText : null, {
        status: i.status,
        data: i.responseText,
        resourceNotExisting: a
      });
    }, i.send(JSON.stringify(e));
  } catch (a) {
    console && console.log(a);
  }
}, z = function(o, t, e, n) {
  if (typeof e == "function" && (n = e, e = void 0), n = n || function() {
  }, L)
    return Te(o, t, e, n);
  if (typeof XMLHttpRequest == "function" || (typeof XMLHttpRequest > "u" ? "undefined" : C(XMLHttpRequest)) === "object" || typeof ActiveXObject == "function")
    return _e(o, t, e, n);
  n(new Error("No fetch and no xhr implementation found!"));
};
function _(s) {
  "@babel/helpers - typeof";
  return _ = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(o) {
    return typeof o;
  } : function(o) {
    return o && typeof Symbol == "function" && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _(s);
}
function Ie(s, o) {
  if (!(s instanceof o)) throw new TypeError("Cannot call a class as a function");
}
function qe(s, o) {
  for (var t = 0; t < o.length; t++) {
    var e = o[t];
    e.enumerable = e.enumerable || !1, e.configurable = !0, "value" in e && (e.writable = !0), Object.defineProperty(s, Re(e.key), e);
  }
}
function Se(s, o, t) {
  return o && qe(s.prototype, o), Object.defineProperty(s, "prototype", { writable: !1 }), s;
}
function Re(s) {
  var o = Ne(s, "string");
  return _(o) == "symbol" ? o : o + "";
}
function Ne(s, o) {
  if (_(s) != "object" || !s) return s;
  var t = s[Symbol.toPrimitive];
  if (t !== void 0) {
    var e = t.call(s, o);
    if (_(e) != "object") return e;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return String(s);
}
var Ue = function() {
  return {
    loadPath: "https://api.locize.app/{{projectId}}/{{version}}/{{lng}}/{{ns}}",
    privatePath: "https://api.locize.app/private/{{projectId}}/{{version}}/{{lng}}/{{ns}}",
    getLanguagesPath: "https://api.locize.app/languages/{{projectId}}",
    addPath: "https://api.locize.app/missing/{{projectId}}/{{version}}/{{lng}}/{{ns}}",
    updatePath: "https://api.locize.app/update/{{projectId}}/{{version}}/{{lng}}/{{ns}}",
    referenceLng: "en",
    crossDomain: !0,
    setContentTypeJSON: !1,
    version: "latest",
    private: !1,
    translatedPercentageThreshold: 0.9,
    failLoadingOnEmptyJSON: !1,
    allowedAddOrUpdateHosts: ["localhost"],
    onSaved: !1,
    reloadInterval: typeof window < "u" ? !1 : 60 * 60 * 1e3,
    checkForProjectTimeout: 3 * 1e3,
    storageExpiration: 60 * 60 * 1e3,
    writeDebounce: 5 * 1e3
  };
}, W;
try {
  W = typeof window < "u" && window.localStorage !== null;
  var te = "notExistingLocizeProject";
  window.localStorage.setItem(te, "foo"), window.localStorage.removeItem(te);
} catch {
  W = !1;
}
function Be(s) {
  var o = function() {
  }, t = function() {
  };
  return W ? (o = function(n) {
    window.localStorage.setItem("notExistingLocizeProject_".concat(n), Date.now());
  }, t = function(n) {
    var i = window.localStorage.getItem("notExistingLocizeProject_".concat(n));
    return i ? Date.now() - i > s ? (window.localStorage.removeItem("notExistingLocizeProject_".concat(n)), !1) : !0 : !1;
  }) : typeof document < "u" && (o = function(n) {
    var i = /* @__PURE__ */ new Date();
    i.setTime(i.getTime() + s);
    var a = "; expires=".concat(i.toGMTString()), c = "notExistingLocizeProject_".concat(n);
    try {
      document.cookie = "".concat(c, "=").concat(Date.now()).concat(a, ";path=/");
    } catch {
    }
  }, t = function(n) {
    var i = "notExistingLocizeProject_".concat(n), a = "".concat(i, "=");
    try {
      for (var c = document.cookie.split(";"), u = 0; u < c.length; u++) {
        for (var d = c[u]; d.charAt(0) === " "; ) d = d.substring(1, d.length);
        if (d.indexOf(a) === 0) return !0;
      }
    } catch {
    }
    return !1;
  }), {
    setProjectNotExisting: o,
    isProjectNotExisting: t
  };
}
var M = function(o, t, e) {
  var n = {};
  return t.authorize && t.apiKey && (n.Authorization = t.apiKey), (e || t.setContentTypeJSON) && (n["Content-Type"] = "application/json"), {
    method: e ? "POST" : "GET",
    url: o,
    headers: n,
    body: e
  };
}, F = function(o, t, e) {
  if (o.request.length === 1) {
    try {
      var n = o.request(t);
      n && typeof n.then == "function" ? n.then(function(i) {
        return e(null, i);
      }).catch(e) : e(null, n);
    } catch (i) {
      e(i);
    }
    return;
  }
  o.request(t, e);
}, se = function() {
  function s(o) {
    var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, e = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, n = arguments.length > 3 ? arguments[3] : void 0;
    Ie(this, s), this.services = o, this.options = t, this.allOptions = e, this.type = "backend", o && o.projectId ? this.init(null, o, e, t) : this.init(o, t, e, n);
  }
  return Se(s, [{
    key: "init",
    value: function(t) {
      var e = this, n = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, i = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, a = arguments.length > 3 ? arguments[3] : void 0;
      !n.referenceLng && i.fallbackLng && Array.isArray(i.fallbackLng) && i.fallbackLng[0] !== "dev" && (n.referenceLng = i.fallbackLng[0]), this.services = t;
      var c = Ue(), u = T(n, this.options || {});
      u.reloadInterval && u.reloadInterval < 5 * 60 * 1e3 && (console.warn("Your configured reloadInterval option is to low."), u.reloadInterval = c.reloadInterval), this.options = T(n, this.options || {}, c), this.allOptions = i, this.somethingLoaded = !1, this.isProjectNotExisting = !1, this.storage = Be(this.options.storageExpiration), this.options.pull && console.warn('The pull API was removed use "private: true" option instead: https://docs.locize.com/integration/api#fetch-private-namespace-resources');
      var d = typeof window < "u" && window.location && window.location.hostname;
      d ? (this.isAddOrUpdateAllowed = typeof this.options.allowedAddOrUpdateHosts == "function" ? this.options.allowedAddOrUpdateHosts(d) : this.options.allowedAddOrUpdateHosts.indexOf(d) > -1, t && t.logger && (i.saveMissing || i.updateMissing) && (this.isAddOrUpdateAllowed ? d !== "localhost" && t.logger.warn('locize-backend: you are using the save or update missings feature from this host "'.concat(d, `".
Make sure you will not use it in production!
https://docs.locize.com/guides-tips-and-tricks/going-production`)) : t.logger.warn(typeof this.options.allowedAddOrUpdateHosts == "function" ? 'locize-backend: will not save or update missings because allowedAddOrUpdateHosts returned false for the host "'.concat(d, '".') : 'locize-backend: will not save or update missings because the host "'.concat(d, '" was not in the list of allowedAddOrUpdateHosts: ').concat(this.options.allowedAddOrUpdateHosts.join(", "), " (matches need to be exact).")))) : this.isAddOrUpdateAllowed = !0, typeof a == "function" && this.getOptions(function(h, l, p) {
        if (h) return a(h);
        e.options.referenceLng = n.referenceLng || l.referenceLng || e.options.referenceLng, a(null, l, p);
      }), this.queuedWrites = {
        pending: {}
      }, this.debouncedProcess = je(this.process, this.options.writeDebounce), this.interval && clearInterval(this.interval), this.options.reloadInterval && this.options.projectId && (this.interval = setInterval(function() {
        return e.reload();
      }, this.options.reloadInterval), _(this.interval) === "object" && typeof this.interval.unref == "function" && this.interval.unref());
    }
  }, {
    key: "reload",
    value: function() {
      var t = this, e = this.services || {
        logger: console
      }, n = e.backendConnector, i = e.languageUtils, a = e.logger;
      if (n) {
        var c = n.language;
        if (!(c && c.toLowerCase() === "cimode")) {
          var u = [], d = function(l) {
            var p = i.toResolveHierarchy(l);
            p.forEach(function(w) {
              u.indexOf(w) < 0 && u.push(w);
            });
          };
          d(c), this.allOptions.preload && this.allOptions.preload.forEach(function(h) {
            return d(h);
          }), u.forEach(function(h) {
            t.allOptions.ns.forEach(function(l) {
              n.read(h, l, "read", null, null, function(p, w) {
                p && a.warn("loading namespace ".concat(l, " for language ").concat(h, " failed"), p), !p && w && a.log("loaded namespace ".concat(l, " for language ").concat(h), w), n.loaded("".concat(h, "|").concat(l), p, w);
              });
            });
          });
        }
      }
    }
  }, {
    key: "getLanguages",
    value: function(t) {
      var e = this, n;
      t || (n = Y(), t = function(u, d) {
        if (u) return n.reject(u);
        n.resolve(d);
      });
      var i = q(this.options, ["projectId"]);
      if (i) return t(new Error(i));
      var a = I(this.options.getLanguagesPath, {
        projectId: this.options.projectId
      });
      if (!this.isProjectNotExisting && this.storage.isProjectNotExisting(this.options.projectId) && (this.isProjectNotExisting = !0), this.isProjectNotExisting) return t(new Error("locize project ".concat(this.options.projectId, " does not exist!")));
      if (this.getLanguagesCalls = this.getLanguagesCalls || [], this.getLanguagesCalls.push(t), !(this.getLanguagesCalls.length > 1))
        return this.loadUrl({}, a, function(c, u, d) {
          if (!e.somethingLoaded && d && d.resourceNotExisting) {
            e.isProjectNotExisting = !0, e.storage.setProjectNotExisting(e.options.projectId);
            var h = new Error("locize project ".concat(e.options.projectId, " does not exist!")), l = e.getLanguagesCalls;
            return e.getLanguagesCalls = [], l.forEach(function(j) {
              return j(h);
            });
          }
          if (u) {
            var p = Object.keys(u).reduce(function(j, y) {
              var E = u[y];
              return E.isReferenceLanguage && (j = y), j;
            }, "");
            p && e.options.referenceLng !== p && (e.options.referenceLng = p);
          }
          e.somethingLoaded = !0;
          var w = e.getLanguagesCalls;
          e.getLanguagesCalls = [], w.forEach(function(j) {
            return j(c, u);
          });
        }), n;
    }
  }, {
    key: "getOptions",
    value: function(t) {
      var e = this, n;
      return t || (n = Y(), t = function(a, c) {
        if (a) return n.reject(a);
        n.resolve(c);
      }), this.getLanguages(function(i, a) {
        if (i) return t(i);
        var c = Object.keys(a);
        if (!c.length)
          return t(new Error("was unable to load languages via API"));
        var u = c.reduce(function(h, l) {
          var p = a[l];
          return p.translated[e.options.version] && p.translated[e.options.version] >= e.options.translatedPercentageThreshold && h.push(l), h;
        }, []), d = c.reduce(function(h, l) {
          return l.indexOf("-") > -1 ? !0 : h;
        }, !1);
        t(null, {
          fallbackLng: e.options.referenceLng,
          referenceLng: e.options.referenceLng,
          supportedLngs: u,
          load: d ? "all" : "languageOnly"
        }, a);
      }), n;
    }
  }, {
    key: "checkIfProjectExists",
    value: function(t) {
      var e = this, n = this.services || {
        logger: console
      }, i = n.logger;
      if (this.somethingLoaded) {
        t && t(null);
        return;
      }
      if (this.alreadyRequestedCheckIfProjectExists) {
        setTimeout(function() {
          return e.checkIfProjectExists(t);
        }, this.options.checkForProjectTimeout);
        return;
      }
      this.alreadyRequestedCheckIfProjectExists = !0, this.getLanguages(function(a) {
        a && a.message && a.message.indexOf("does not exist") > 0 && i && i.error(a.message), t && t(a);
      });
    }
  }, {
    key: "read",
    value: function(t, e, n) {
      var i = this, a = this.services || {
        logger: console
      }, c = a.logger, u, d = {};
      if (this.options.private) {
        var h = q(this.options, ["projectId", "version", "apiKey"]);
        if (h) return n(new Error(h), !1);
        u = I(this.options.privatePath, {
          lng: t,
          ns: e,
          projectId: this.options.projectId,
          version: this.options.version
        }), d = {
          authorize: !0
        };
      } else {
        var l = q(this.options, ["projectId", "version"]);
        if (l) return n(new Error(l), !1);
        u = I(this.options.loadPath, {
          lng: t,
          ns: e,
          projectId: this.options.projectId,
          version: this.options.version
        });
      }
      if (!this.isProjectNotExisting && this.storage.isProjectNotExisting(this.options.projectId) && (this.isProjectNotExisting = !0), this.isProjectNotExisting) {
        var p = new Error("locize project ".concat(this.options.projectId, " does not exist!"));
        c && c.error(p.message), n && n(p);
        return;
      }
      this.loadUrl(d, u, function(w, j, y) {
        i.somethingLoaded || (y && y.resourceNotExisting ? setTimeout(function() {
          return i.checkIfProjectExists();
        }, i.options.checkForProjectTimeout) : i.somethingLoaded = !0), n(w, j);
      });
    }
  }, {
    key: "loadUrl",
    value: function(t, e, n, i) {
      var a = this;
      t = T(t, this.options), typeof n == "function" && (i = n, n = void 0), i = i || function() {
      };
      var c = function(h, l) {
        var p = l && l.resourceNotExisting;
        if (l && (l.status === 408 || l.status === 400))
          return i("failed loading " + e, !0, {
            resourceNotExisting: p
          });
        if (l && (l.status >= 500 && l.status < 600 || !l.status))
          return i("failed loading " + e, !0, {
            resourceNotExisting: p
          });
        if (l && l.status >= 400 && l.status < 500)
          return i("failed loading " + e, !1, {
            resourceNotExisting: p
          });
        if (!l && h && h.message) {
          var w = h.message.toLowerCase(), j = ["failed", "fetch", "network", "load"].find(function(x) {
            return w.indexOf(x) > -1;
          });
          if (j)
            return i("failed loading " + e + ": " + h.message, !0, {
              resourceNotExisting: p
            });
        }
        if (h) return i(h, !1);
        var y, E;
        try {
          typeof l.data == "string" ? y = JSON.parse(l.data) : y = l.data;
        } catch {
          E = "failed parsing " + e + " to json";
        }
        if (E) return i(E, !1);
        if (a.options.failLoadingOnEmptyJSON && !Object.keys(y).length)
          return i("loaded result empty for " + e, !1, {
            resourceNotExisting: p
          });
        i(null, y, {
          resourceNotExisting: p
        });
      };
      if (!this.options.request || e.indexOf("/languages/".concat(t.projectId)) > 0) return z(t, e, n, c);
      var u = M(e, t, n);
      F(this.options, u, c);
    }
  }, {
    key: "create",
    value: function(t, e, n, i, a, c) {
      var u = this;
      typeof a != "function" && (a = function() {
      }), this.checkIfProjectExists(function(d) {
        if (d) return a(d);
        var h = q(u.options, ["projectId", "version", "apiKey", "referenceLng"]);
        if (h) return a(new Error(h));
        if (!u.isAddOrUpdateAllowed)
          return a("host is not allowed to create key.");
        typeof t == "string" && (t = [t]), t.filter(function(l) {
          return l === u.options.referenceLng;
        }).length < 1 && u.services && u.services.logger && u.services.logger.warn('locize-backend: will not save missings because the reference language "'.concat(u.options.referenceLng, '" was not in the list of to save languages: ').concat(t.join(", "), " (open your site in the reference language to save missings).")), t.forEach(function(l) {
          l === u.options.referenceLng && u.queue.call(u, u.options.referenceLng, e, n, i, a, c);
        });
      });
    }
  }, {
    key: "update",
    value: function(t, e, n, i, a, c) {
      var u = this;
      typeof a != "function" && (a = function() {
      }), this.checkIfProjectExists(function(d) {
        if (d) return a(d);
        var h = q(u.options, ["projectId", "version", "apiKey", "referenceLng"]);
        if (h) return a(new Error(h));
        if (!u.isAddOrUpdateAllowed)
          return a("host is not allowed to update key.");
        c || (c = {}), typeof t == "string" && (t = [t]), c.isUpdate = !0, t.forEach(function(l) {
          l === u.options.referenceLng && u.queue.call(u, u.options.referenceLng, e, n, i, a, c);
        });
      });
    }
  }, {
    key: "writePage",
    value: function(t, e, n, i) {
      var a = I(this.options.addPath, {
        lng: t,
        ns: e,
        projectId: this.options.projectId,
        version: this.options.version
      }), c = I(this.options.updatePath, {
        lng: t,
        ns: e,
        projectId: this.options.projectId,
        version: this.options.version
      }), u = !1, d = !1, h = {}, l = {};
      n.forEach(function(E) {
        var x = E.options && E.options.tDescription ? {
          value: E.fallbackValue || "",
          context: {
            text: E.options.tDescription
          }
        } : E.fallbackValue || "";
        E.options && E.options.isUpdate ? (d || (d = !0), l[E.key] = x) : (u || (u = !0), h[E.key] = x);
      });
      var p = 0;
      u && p++, d && p++;
      var w = function(x) {
        p--, p || i(x);
      };
      if (p || w(), u)
        if (!this.options.request)
          z(T({
            authorize: !0
          }, this.options), a, h, w);
        else {
          var j = M(a, T({
            authorize: !0
          }, this.options), h);
          F(this.options, j, w);
        }
      if (d)
        if (!this.options.request)
          z(T({
            authorize: !0
          }, this.options), c, l, w);
        else {
          var y = M(c, T({
            authorize: !0
          }, this.options), l);
          F(this.options, y, w);
        }
    }
  }, {
    key: "write",
    value: function(t, e) {
      var n = this, i = $(this.queuedWrites, ["locks", t, e]);
      if (!i) {
        var a = $(this.queuedWrites, [t, e]);
        H(this.queuedWrites, [t, e], []);
        var c = 1e3, u = a.filter(function(w) {
          return w.callback;
        }).map(function(w) {
          return w.callback;
        });
        if (a.length) {
          H(this.queuedWrites, ["locks", t, e], !0);
          var d = function() {
            H(n.queuedWrites, ["locks", t, e], !1), u.forEach(function(j) {
              return j();
            }), n.options.onSaved && n.options.onSaved(t, e), n.debouncedProcess(t, e);
          }, h = a.length / c, l = 0, p = a.splice(0, c);
          for (this.writePage(t, e, p, function() {
            l++, l >= h && d();
          }); p.length === c; )
            p = a.splice(0, c), p.length && this.writePage(t, e, p, function() {
              l++, l >= h && d();
            });
        }
      }
    }
  }, {
    key: "process",
    value: function() {
      var t = this;
      Object.keys(this.queuedWrites).forEach(function(e) {
        e !== "locks" && Object.keys(t.queuedWrites[e]).forEach(function(n) {
          var i = t.queuedWrites[e][n];
          i.length && t.write(e, n);
        });
      });
    }
  }, {
    key: "queue",
    value: function(t, e, n, i, a, c) {
      Oe(this.queuedWrites, [t, e], {
        key: n,
        fallbackValue: i || "",
        callback: a,
        options: c
      }), this.debouncedProcess();
    }
  }]);
}();
se.type = "backend";
const re = !1, He = (s) => (V.use(se).use(ye).init({
  backend: {
    projectId: s.projectId,
    apiKey: s.apiKey,
    referenceLng: "en"
  },
  fallbackLng: "en",
  supportedLngs: ["en", "fr-CA"],
  ns: [
    "common",
    "errors",
    "dashboard",
    "auth",
    "forms",
    "tooltips",
    "notifications"
  ],
  defaultNS: "common",
  saveMissing: !re,
  saveMissingTo: "current",
  debug: !re,
  interpolation: {
    escapeValue: !1
  }
}), V), ze = () => {
  const { i18n: s } = ve(), o = we(
    async (t) => {
      try {
        return await s.changeLanguage(t), localStorage.setItem("i18nextLng", t), !0;
      } catch (e) {
        return console.error("Failed to change language:", e), !1;
      }
    },
    [s]
  );
  return {
    currentLanguage: s.language,
    changeLanguage: o,
    languages: {
      en: "English",
      "fr-CA": "Français (CA)"
    }
  };
};
export {
  He as initializeI18n,
  ze as useLanguage
};
